$profile_picture: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjMgMCAxOCAxOCIgZmlsbD0icmdiYSgyNTAsMjUwLDI1MCwuNSkiPjx0aXRsZT5hY2NvdW50PC90aXRsZT48cGF0aCBkPSJNMTIsNEE0LDQgMCAwLDEgMTYsOEE0LDQgMCAwLDEgMTIsMTJBNCw0IDAgMCwxIDgsOEE0LDQgMCAwLDEgMTIsNE0xMiwxNEMxNi40MiwxNCAyMCwxNS43OSAyMCwxOFYyMEg0VjE4QzQsMTUuNzkgNy41OCwxNCAxMiwxNFoiIC8+PC9zdmc+');

.root {
  .main {
    width: 100%;
    padding-inline: 40px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14),
      0 3px 3px -2px rgba(0, 0, 0, 0.2), 0 1px 8px 0 rgba(0, 0, 0, 0.12);
    border-radius: 5px;
    overflow: auto;
    scrollbar-width: thin;
  }

  .loading {
    opacity: 0.4;
    justify-content: center;
    align-items: center;
    margin: 140px 0px;

    :global(.MuiCircularProgress-root) {
      color: var(--text-primary);
      width: 30px !important;
      height: 30px !important;
    }
  }

  .main::-webkit-scrollbar {
    width: 8px;
  }

  .main::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  .main::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }

  .main::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

  .header_infos {
    display: flex;
    align-items: center;
    height: 36px;
    gap: 12px;
  }

  .icon_list {
    display: flex;
    margin: 18px auto;
    list-style: none;
    justify-content: space-between;
    align-items: center;
    padding-inline: 24px;
    height: 180px;
    overflow-x: scroll;
  }

  .coin_icon {
    cursor: pointer;
    width: 130px;
    height: 130px;
  }

  .leagues_info_container {
    width: 100%;
  }

  .league_info {
    text-align: center;
    color: var(--Ranking-title);
    font-weight: 700;
    font-size: 22px;
    text-transform: uppercase;
  }

  .league_info_description {
    text-align: center;
    color: var(--Ranking-paragraph);
    font-weight: 600;
    font-size: 14px;
  }

  .league_remaining_time {
    text-align: center;
    color: var(--Ranking-paragraph);
    font-size: 14px;
  }

  .league_remaining_time_hours {
    font-weight: 700;
    margin-right: 16px;
    color: var(--Ranking-experience);
    text-transform: uppercase;
  }

  .ranking_card {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 12px 18px;
    border-radius: 8px;
    background: var(--Ranking-gradient);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
    color: var(--Ranking-card);
    align-items: center;
    gap: 18px;
  }

  .ranking_card_name {
    font-weight: 700;
    font-size: 10px;
  }

  .wrapper {
    padding-inline: 18px;
  }

  .ranking_list {
    width: 100%;
  }

  .ranking_card_list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 450px;
    margin: 0 auto;
  }

  .ranking_card_img {
    border-radius: 50%;
    width: 30px;
    height: 30px;
  }

  .ranking_card_profile {
    display: flex;
    align-items: center;
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    gap: 18px;
    white-space: nowrap;
  }

  // .selected {
  //   transition: 0.4s;
  //   transition-timing-function: ease-in;
  //   transform: scale(1.05);
  // }

  .league_info_description,
  .league_remaining_time {
    margin-block: 16px;
  }

  .ranking_position {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    font-weight: 700;
    text-align: center;
    color: var(--Ranking-card);
    font-size: 24px;
  }

  .ranking_position_promotion {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    font-weight: 700;
    text-align: center;
    color: #7ecf21;
    font-size: 24px;
  }

  .ranking_position_downgrade {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    font-weight: 700;
    text-align: center;
    color: #d15656;
    font-size: 24px;
  }

  .medal_icon {
    width: 50px;
    height: 50px;
  }

  .first_place {
    background: var(--Ranking-firstPlace);
  }

  .second_place {
    background: var(--Ranking-secondPlace);
  }

  .third_place {
    background: var(--Ranking-thirdPlace);
  }

  .ranking_card_exp {
    font-weight: 700;
    color: var(--Ranking-experience);
  }

  .infos_underline {
    width: 100%;
    height: 20px;
    background-color: var(--Ranking-underline);
    border-radius: 8px;
    margin: 12px auto;
  }

  .promotion_paragraph {
    align-items: center;
    color: #7ecf21;
    text-align: center;
    font-weight: 600;
  }

  .downgrade_paragraph {
    color: #d15656;
    text-align: center;
    font-weight: 600;
  }

  .promotion_paragraph,
  .downgrade_paragraph {
    font-weight: 600;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
    text-transform: uppercase;
    margin: 0 auto;
    padding: 8px;
  }

  .arrow_promotion {
    transform: rotate(180deg);
  }

  .zone_container {
    width: 240px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
  }

  .locked {
    filter: grayscale(100%);
  }

  .unlocked {
    filter: grayscale(0);
  }

  @media (min-width: 768px) {
    .main {
      width: 100%;
      height: 100%;
    }
    .ranking_card_profile {
      gap: 42px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .ranking_card_name {
      font-weight: 600;
      font-size: 14px;
    }

    .icon_list {
      width: 850px;
      height: 180px;
      overflow-x: hidden;
    }

    .infos_underline {
      width: 850px;
      height: 10px;
      background-color: var(--Ranking-underline);
      border-radius: 8px;
      margin: 12px auto;
    }
    .league_info {
      text-align: center;
      color: var(--Ranking-title);
      font-weight: 700;
      font-size: 36px;
      text-transform: uppercase;
    }
    .ranking_card_profile {
      display: flex;
      align-items: center;
      flex: 1;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .ranking_card_list {
      display: flex;
      flex-direction: column;
      gap: 20px;
      height: 600px;
      width: 840px;
      margin: 0 auto;
    }
  }
}
