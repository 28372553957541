@property --angle {
    syntax: '<angle>';
    initial-value: -90deg;
    inherits: false
}

body {
    --border-hover-color: 42, 42, 42;

    &:global(.theme-dark) {
        --border-hover-color: 255, 255, 255;
    }
}

.card {
    padding: 4px;
    min-width: 400px;
    width: auto;
    height: 200px;
    background-color: transparent;
    --color: #e0e0e0;

    &::after {
        content: '';
        border-radius: 12px;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        display: block;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        transition: opacity .1s ease-out;
        z-index: 4;
        box-sizing: border-box;
        padding: 2px;

        @supports (-webkit-mask: linear-gradient(to top, #f00, #008000)) and ((-webkit-mask-composite:xor) or (mask-composite:exclude)) {
            border: 0;
            -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
            -webkit-mask-composite: xor;
            mask-composite: exclude;
            --angle: -90deg;
            background: linear-gradient(to bottom, rgba(var(--border-hover-color), .8), rgba(var(--border-hover-color), .15));
            background: conic-gradient(from var(--angle), rgba(var(--border-hover-color), .8), rgba(var(--border-hover-color), .15), rgba(var(--border-hover-color), .8));
        }
    }

    &:hover::after {
        opacity: 1;
        -webkit-animation: 8s rotate linear infinite;
        animation: 8s rotate linear infinite;

        @-webkit-keyframes rotate {
            to {
                --angle: 270deg;
            }
        }

        @keyframes rotate {
            to {
                --angle: 270deg;
            }
        }
    }

    >div {
        border-radius: 8px;
        overflow: hidden;
        width: 100%;
        height: 100%;
        background-color: var(--WindowComponent-content-background);
        box-shadow: 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12), 0 5px 5px -3px rgba(0, 0, 0, .2);
        display: flex;
        flex-direction: row;

        &::before {
            content: '';
            position: absolute;
            top: 0px;
            left: 0px;
            width: 200%;
            height: 200%;
            background-image: radial-gradient(var(--color) 50%, rgba(0, 0, 0, 1) 80%);
            opacity: .2;
        }

        >.card-header {
            flex: 1;
            width: 250px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: center;
            padding: 15px;
            overflow: hidden;

            * {
                word-break: break-word;
                font-family: "din-round", sans-serif !important;
                // color: #fafafa;
            }
        }

        >.card-image {
            width: 200px;
            height: 100%;
            filter: drop-shadow(-1px 6px 8px rgba(0, 0, 0, 0.5));

            >div {
                clip-path: polygon(17% 0, 100% 0, 100% 100%, 26% 100%, 0 88%);
                box-shadow: 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12), 0 5px 5px -3px rgba(0, 0, 0, .2);
                // background-color: var(--color);
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                width: 100%;
                height: 100%;
            }
        }
    }

    @media screen and (max-width: 600px) {
        width: 100%;
        height: auto;
        min-width: 0px;

        >div {
            height: auto;
            flex-direction: column-reverse;

            &::before {
                bottom: 0px;
                top: auto;
                left: auto;
                right: -20%;
            }

            >div {
                width: 100% !important;
            }

            >.card-image {
                width: 100%;
                height: 250px;

                >div {
                    clip-path: polygon(0 0, 100% 0, 100% 55%, 88% 100%, 0 88%);
                    background-position: center top;
                }

                &.card-image-in-view {
                    >div {
                        background-position: center top;
                        background-size: auto 85%;
                    }
                }
            }
        }
    }
}