.cancelContractDialog-text {
    padding: 10px 15px;

    :global(.MuiTypography-h6) {
        margin-bottom: 5px;
    }

    :global(.MuiTypography-subtitle2) {
        text-align: justify;
    }
}

.Wallet-Component {
    position: relative;
    min-height: 100%;

    .WalletList {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-evenly;
        padding: 40px 20px;

        >div {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 300px;
            height: 180px;
            cursor: pointer;
            margin: 15px;
            background-color: var(--Wallets-item-background);
            border-radius: 10px;
            overflow: hidden;

            .WalletLogo {
                position: absolute;
                top: 5px;
                bottom: 5px;
                left: 5px;
                right: 5px;
                width: auto;
                height: auto;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: center;
                opacity: .2;

                svg {
                    width: auto;
                    height: 190px;
                    transform: translate(25%, 25%);
                }
            }
        }

        .WalletInfo {
            position: absolute;
            top: 10px;
            left: 15px;
            display: flex;
            flex-direction: column;

            >* {
                line-height: 1.4;
            }
        }

        .WalletPrice {
            padding-top: 15px;

            >* {
                font-weight: bold;
            }
        }

        .WalletDate {
            position: absolute;
            bottom: 10px;
            left: 15px;
            opacity: .5;
        }

        .WalletType {
            position: absolute;
            bottom: 10px;
            right: 2px;
            writing-mode: tb-rl;
            filter: flipv fliph;
            opacity: .3;
        }

        >div.WalletList-btn-new {
            >* {
                opacity: .6;
            }

            &:hover>* {
                opacity: 1;
            }

            >svg {
                width: 50px;
                height: 50px;
                margin-bottom: 15px;
            }
        }

        .WalletDefaultSpace {
            height: 0px;
            margin-top: 0px;
            margin-bottom: 0px;
        }

        >div,
        >div * {
            transition: opacity .2s ease-in-out;
            color: var(--Wallets-item-color);
        }
    }
}