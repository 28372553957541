.main {
    width: 100%;
    height: 100%;
    max-height: 30px !important;
    background-color: transparent;
    display: flex;
    justify-content: space-between;
    z-index: 9;

    .content {
        flex: 1;
        overflow: hidden;

        .wallets_bar {
            position: relative;
            max-width: 100%;
            display: flex;
            height: 100%;
            display: flex;
            overflow: hidden;
            flex-wrap: wrap;

            .eye {
                padding: 0px 0px 0px 10px;
                height: 30px;
                display: flex;
                align-items: center;
                opacity: .6;

                &:hover {
                    opacity: 1;
                }
            }

            div:not(.eye) {
                height: 30px;
                background-color: transparent;
                color: var(--text-primary);
                border-radius: 0px 0px;
                padding: 0px 10px;
                margin: 0px 3px;
                display: flex;
                align-items: center;
                cursor: pointer;
                overflow-x: hidden;
                flex-wrap: nowrap;

                &:hover {
                    background-color: rgba(0, 0, 0, .1);
                }

                &>svg {
                    font-size: 18px;
                }

                &>span {
                    margin-left: 10px;
                    font-size: 14px;
                }
            }
        }
    }
}

.Popover {
    display: flex;
    flex-direction: column;
    background-color: var(--Wallets-item-background);
    color: var(--Wallets-item-color);

    &>div {
        position: relative;
        width: 190px;
        height: 100px;

        &:first-child {
            position: absolute;
            top: 0px;
            bottom: 0px;
            left: 0px;
            right: 0px;
            overflow: hidden;

            &>svg {
                position: absolute;
                bottom: 0px;
                right: 0px;
                width: auto;
                height: 110px;
                transform: translate(25%, 25%);
                opacity: .2;
            }

            .WalletType {
                position: absolute;
                bottom: 5px;
                right: 0px;
                font-size: 10px;
                writing-mode: tb-rl;
                filter: flipv fliph;
                opacity: .3;
            }
        }

        &:last-child {
            display: flex;
            flex-direction: column;
            padding: 10px;
            justify-content: space-between;

            &>.WalletInfo {
                display: flex;
                flex-direction: column;

                &>* {
                    line-height: 1.4;
                    font-size: 10px;
                    overflow-x: hidden;
                    white-space: nowrap;
                }
            }

            &>.WalletPrice {
                font-weight: bold;
            }
        }
    }
}