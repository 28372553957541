.HorizontalButtonGroup{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 25px;

    :global(.MuiButtonGroup-root){
        width: auto;
        display: flex !important;
        flex-direction: row !important;
        flex-wrap: nowrap !important;
        background: var(--Wallets-infoContent-background);
        backdrop-filter: blur(35px);
        border-radius: 5px;
        border: 2px solid var(--Wallets-infoContent-border);

        > button{
            background-color: transparent;
            color: var(--text-primary) !important;
            border: 0px solid var(--Wallets-infoContent-border) !important;
            flex-wrap: nowrap !important;
            white-space: nowrap !important;
            border-left-width: 2px !important;
            border-right-width: 0px !important;

            &:first-child{
                border-left-width: 0px !important;
            }

            &:global(.Mui-disabled){
                color: var(--action-disabled) !important;
            }
        }
    }
}