@property --hue {
    syntax: "<number>";
    inherits: true;
    initial-value: 0;
}

@property --rotate {
    syntax: "<number>";
    inherits: true;
    initial-value: 0;
}

@property --bg-y {
    syntax: "<number>";
    inherits: true;
    initial-value: 0;
}

@property --bg-x {
    syntax: "<number>";
    inherits: true;
    initial-value: 0;
}

@property --glow-translate-y {
    syntax: "<number>";
    inherits: true;
    initial-value: 0;
}

@property --bg-size {
    syntax: "<number>";
    inherits: true;
    initial-value: 0;
}

@property --glow-opacity {
    syntax: "<number>";
    inherits: true;
    initial-value: 0;
}

@property --glow-blur {
    syntax: "<number>";
    inherits: true;
    initial-value: 0;
}

@property --glow-scale {
    syntax: "<number>";
    inherits: true;
    initial-value: 2;
}

@property --glow-radius {
    syntax: "<number>";
    inherits: true;
    initial-value: 2;
}

@property --white-shadow {
    syntax: "<number>";
    inherits: true;
    initial-value: 0;
}

:global(body.theme-dark) {
    .main {
        --color: rgba(255, 255, 255, .7);
        --color-first: 55, 71, 79;
        --color-second: 246, 194, 38;
        --color-third: 252, 85, 255;
        --color-fourth: 0, 4, 55;
    }
}

.main {
    --color: rgba(255, 255, 255, .7);

    // utilities
    --debug: 0;
    --supported: 0;
    --not-supported: 0;

    // Pen vars
    --card-color: var(--WindowComponent-content-background);
    --card-radius: 20px;
    --card-width: 35vw;
    --border-width: 3px;
    --bg-size: 1;

    --hue: 0;
    --hue-speed: 1;

    --rotate: 0;
    --animation-speed: 4s;

    --interaction-speed: 0.55s;
    --glow-scale: 1.5;
    --scale-factor: 1;
    --glow-blur: 6; // 6
    --glow-opacity: 1; // 0.6
    --glow-radius: 100; // 100
    --glow-rotate-unit: 1deg;

    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    >div {
        width: 80%;
        max-width: 400px;
        height: 80%;
        color: var(--text-primary) !important;
        margin: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        z-index: 2;
        border-radius: var(--card-radius);

        * {
            color: var(--text-primary) !important;
        }

        &:before,
        &:after {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: var(--card-radius);
        }

        >div {
            position: absolute;
            width: 100%;
            height: 100%;
            background: var(--card-color);
            border-radius: calc(calc(var(--card-radius) * 0.9));
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            padding: 0px 20px 20px 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;

            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100%;
                height: 100%;
                border-radius: calc(calc(var(--card-radius) * 0.9));
                // box-shadow: 0 0 20px black;
                mix-blend-mode: color-burn;
                z-index: -1;
                background: rgba(0, 0, 0, .1) radial-gradient(30% 30% at calc(var(--bg-x) * 1%) calc(var(--bg-y) * 1%),
                        hsl(calc(calc(var(--hue) * var(--hue-speed)) * 1deg) 100% 90%) calc(0% * var(--bg-size)),
                        hsl(calc(calc(var(--hue) * var(--hue-speed)) * 1deg) 100% 80%) calc(20% * var(--bg-size)),
                        hsl(calc(calc(var(--hue) * var(--hue-speed)) * 1deg) 100% 60%) calc(40% * var(--bg-size)),
                        transparent 100%);
                width: calc(100% + var(--border-width));
                height: calc(100% + var(--border-width));
                animation: hue-animation var(--animation-speed) linear infinite,
                    rotate-bg var(--animation-speed) linear infinite;
                transition: --bg-size var(--interaction-speed) ease;
            }
        }
    }

    @keyframes rotate-bg {
        0% {
            --bg-x: 0;
            --bg-y: 0;
        }

        25% {
            --bg-x: 100;
            --bg-y: 0;
        }

        50% {
            --bg-x: 100;
            --bg-y: 100;
        }

        75% {
            --bg-x: 0;
            --bg-y: 100;
        }

        100% {
            --bg-x: 0;
            --bg-y: 0;
        }
    }

    @keyframes rotate {
        from {
            --rotate: -70;
            --glow-translate-y: -65;
        }

        25% {
            --glow-translate-y: -65;
        }

        50% {
            --glow-translate-y: -65;
        }

        60%,
        75% {
            --glow-translate-y: -65;
        }

        85% {
            --glow-translate-y: -65;
        }

        to {
            --rotate: calc(360 - 70);
            --glow-translate-y: -65;
        }
    }

    @keyframes hue-animation {
        0% {
            --hue: 0;
        }

        100% {
            --hue: 360;
        }
    }

    svg {
        width: 100%;
        height: auto;
        flex: 1;

        .path01 {
            stroke-width: 1px;
            fill: currentColor;
            letter-spacing: 4px;
        }
    }
}