.container {
    margin: 12px;
    cursor: pointer;

    &.selected {
        &::before {
            content: '';
            position: absolute;
            top: -11px;
            left: -11px;
            right: -11px;
            bottom: -19px;
            width: auto;
            height: auto;
            border-radius: 50%;
            border: 6px solid var(--text-primary);
            z-index: 0;
            opacity: .1;
        }
    }

    .startBtn {
        position: absolute;
        bottom: 95%;
        left: 50%;
        transform: translateX(-50%);
        z-index: 4;
        background-color: var(--WindowComponent-background);
        border-radius: 10px;
        border: 3px solid rgba(0, 0, 0, 0.2);
        padding: 2px 10px;
        text-transform: uppercase;
        box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
        animation: floating 2s ease-out infinite;
        animation-fill-mode: backwards;

        >* {
            margin: 0px;
            z-index: 1;
        }

        &::before {
            content: '';
            position: absolute;
            left: 50%;
            bottom: -6px;
            height: 10px;
            width: 10px;
            background-color: var(--WindowComponent-background);
            border: 3px solid rgba(0, 0, 0, 0.2);
            border-left-width: 0px;
            border-top-width: 0px;
            border-bottom-right-radius: 5px;
            transform: translateX(-50%) rotate(45deg);
            z-index: 0;
        }

        @keyframes floating {
            0% {
                bottom: 105%;
            }

            50% {
                bottom: 95%;
            }

            100% {
                bottom: 105%;
            }
        }
    }

    .button {
        --color-shadow: #000;
        width: 70px;
        height: 57px;
        border-radius: 50%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        transition: background-color 0.3s ease;
        border: none;
        cursor: pointer;
        box-shadow: 0 8px 0 rgba(0, 0, 0, 0.2), 0 8px 0 var(--color-shadow), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);

        >* {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        >svg {
            width: 80%;
            height: 80%;
            fill: rgba(255, 255, 255, 0.2);
            left: 48%;
        }

        .seal {
            width: 45%;
            height: 45%;
            fill: #fff;
            stroke: #fff;
        }
    }



    &:active {
        &.selected::before {
            top: -1px;
        }

        .button {
            transform: translateY(8px);
            box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2) !important;
        }
    }
}