.notification-container {
    position: fixed;
    display: flex;
    flex-direction: column;
    width: 350px;
    max-width: 90%;
    height: 85vh;
    bottom: 40px;
    right: 10px;
    opacity: 1;
    translate: 0%;
    background-color: transparent;
    transition: all .3s ease-in-out;

    >div:not(.close) {
        --border-radius: 10px;
        background-color: var(--WindowComponent-content-background);
        background-image: linear-gradient(rgba(0, 0, 0, .1), rgba(0, 0, 0, .1));
        border: 2px solid rgba(0, 0, 0, .2);
        border-radius: var(--border-radius);
        margin: 5px 0px;
        width: 100%;
        min-height: 60px;
        box-shadow: 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12), 0 5px 5px -3px rgba(0, 0, 0, .2);
        overflow: hidden;
        display: flex;
        flex-direction: column;

        &.content {
            flex: 1;
        }

        &.actions {
            max-height: 50%;
        }

        >:global(.MuiTypography-root) {
            background-color: rgba(0, 0, 0, .1);
            padding: 8px 15px;
            border-bottom: 2px solid rgba(0, 0, 0, .1);
        }

        >.itens {
            flex: 1;
            overflow: hidden;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            min-height: 40px;
            padding: 5px 0px;

            &:empty {
                justify-content: center;

                &::after {
                    content: attr(data-empty-text);
                    color: var(--text-primary);
                    text-align: center;
                    padding: 10px;
                    opacity: .5;
                }
            }

            >div {
                --color: rgba(0, 0, 0, .1);
                --border-color: rgba(0, 0, 0, .1);
                display: flex;
                flex-direction: row;
                margin: 5px;
                border-radius: var(--border-radius);
                gap: 5px;
                border: 2px solid transparent;

                &.error {
                    --color: var(--error-main);
                    --border-color: var(--error-dark);
                }

                &.warning {
                    --color: var(--warning-main);
                    --border-color: var(--warning-dark);
                }

                &.success {
                    --color: var(--success-main);
                    --border-color: var(--success-dark);
                }

                &::before {
                    content: "";
                    position: absolute;
                    top: -2px;
                    left: -2px;
                    width: 100%;
                    height: 100%;
                    border-radius: 10px;
                    background-color: var(--color);
                    border: 2px solid var(--border-color);
                    opacity: .3;
                }

                >.icon {
                    display: flex;
                    align-items: flex-start;
                    justify-content: center;
                    padding: 10px;
                    border-radius: 10px 0px 0px 10px;

                    >svg {
                        width: 30px;
                        height: 30px;
                        fill: var(--text-primary);
                    }
                }

                >.notification-info {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    padding: 5px 0px;

                    >:global(.MuiTypography-subtitle2) {
                        opacity: .8;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        line-height: 1;
                    }

                    >:global(.MuiTypography-caption) {
                        line-height: 1;
                        opacity: .6;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        background-color: rgba(0, 0, 0, .1);
                        border-radius: 5px;
                        padding: 3px 10px;
                    }

                    >:global(.MuiTypography-body2) {
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin: 5px 0px;
                    }
                }

                >.notification-actions {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding: 5px;
                    gap: 5px;

                    >:global(.MuiButtonBase-root) {
                        padding: 0px;
                        color: var(--text-primary);
                        opacity: .5;

                        &:not(:global(.Mui-disabled)) {
                            &:hover {
                                opacity: 1;
                            }
                        }

                        &:global(.Mui-disabled) {
                            opacity: .2;
                        }

                        >svg {
                            width: 20px;
                            height: 20px;
                        }
                    }
                }
            }
        }
    }

    >.close {
        display: none;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 20px;

        :global(.MuiButtonBase-root) {
            padding: 0px;
            opacity: .5;

            &:hover {
                opacity: 1;
            }

            >svg {
                width: 48px;
                height: 48px;
            }
        }
    }

    &:not(.show) {
        opacity: 0;
        translate: 110% 0%;
    }

    @media screen and (max-width: 600px) {
        top: 0px;
        left: 0px;
        bottom: 0px;
        right: 0px;
        width: auto;
        max-width: 100%;
        height: auto;
        background-color: var(--WindowComponent-content-background);
        padding: 0px;
        translate: 0%;

        >.close {
            display: flex;
        }

        >div:not(.close) {
            --border-radius: 0px;
            box-shadow: none;
            margin: 0px;
            border-left-width: 0px;
            border-right-width: 0px;

            &.actions {
                border-top-width: 0px;
            }
        }

        &:not(.show) {
            translate: 0% 110%;
        }
    }
}