.main {
    display: flex;
    flex-direction: column;
    align-items: center;

    >.filters {
        background: var(--TableCustom-background);
        border: 2px solid var(--TableCustom-border);
        margin: 20px 0px 10px;
        padding: 20px 10px 10px;
        border-radius: 10px;
        width: 100%;
        min-height: 40px;

        &::after {
            content: 'Filtros:';
            position: absolute;
            background: var(--TableCustom-background);
            border: 2px solid var(--TableCustom-border);
            padding: 2px 10px;
            font-size: 14px;
            color: var(--text-secondary);
            border-radius: 10px;
            top: 0px;
            left: 15px;
            transform: translate(0%, -50%);
        }

        >div {
            display: flex;
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0px;
            }

            >.title {
                font-size: 14px;
                font-weight: 700;
                color: var(--text-secondary);
                margin-right: 10px;
                margin-top: 2px;
            }

            >.options {
                display: flex;
                align-items: center;
                gap: 10px;
                flex-wrap: wrap;
                border: 2px solid var(--TableCustom-border);
                flex: 1;
                padding: 5px;
                border-radius: 10px;
            }

            @media screen and (max-width: 700px) {
                flex-direction: column;

                >.title {
                    margin-bottom: 10px;
                }
            }
        }
    }

    >.wrap-table {
        border: 2px solid var(--TableCustom-border);
        border-radius: 10px;
        overflow-x: auto;
        width: 100%;
        container-type: inline-size;
        container-name: table;
        background: var(--TableCustom-background);
        min-height: 200px;
        color: var(--text-secondary);

        >.loading {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 40px;
        }

        >.empty {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 40px;
            font-size: 20px;
            font-style: italic;
        }

        >table {
            border-collapse: collapse;
            width: 100%;
            font-size: 14px;

            th {
                font-weight: 700;
                color: var(--text-secondary);
                font-size: 14px;
                padding: 12px 14px;
                text-align: left;
                background: var(--TableCustom-background);
                border-bottom: 2px solid var(--TableCustom-border);
                z-index: 0;
            }

            td {
                padding: 12px 14px;
                background: var(--TableCustom-background);
                border-bottom: 1px solid var(--TableCustom-border);
                color: var(--text-primary);
                z-index: 0;
                user-select: text;

                >.user__info {
                    display: flex;
                    align-items: center;
                    gap: 12px;

                    >.user__photo {
                        width: 48px;
                        height: 48px;
                        object-fit: cover;
                        border-radius: 100px;
                    }

                    >.user__name {
                        font-size: 14px;
                        font-weight: 600;
                        color: var(--text-primary);
                    }

                    >.user__email {
                        font-size: 12px;
                        color: var(--text-secondary);
                    }
                }

                >progress {
                    display: block;
                    width: 100px;
                    height: 7px;
                    -webkit-appearance: none;
                    border-radius: 10px;

                    &::-webkit-progress-bar {
                        background-color: #eee;
                        border-radius: 10px;
                    }

                    &::-webkit-progress-value {
                        background-color: #10b981;
                        border-radius: 10px;
                    }
                }
            }
        }

        @container table (min-width: 400px) {
            .sticky-col {
                position: sticky;
                left: 0;
                z-index: 1;
            }

            .sticky-col-right {
                right: 0;
                z-index: 1;
            }
        }

        @container table (max-width: 400px) {
            label {
                display: none;
            }

            tr {
                td::before {
                    content: attr(data-label);
                    font-weight: 700;
                    color: var(--text-secondary);
                    font-size: 14px;
                }

                td {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    border-bottom: none;
                }

                td:first-child {
                    background: rgba(0, 0, 0, .1);
                }

                td:last-child {
                    border-bottom: 2px solid var(--TableCustom-border);
                }

                &:last-child {
                    td:last-child {
                        border-bottom: none;
                    }
                }
            }

            th {
                display: none;
            }
        }
    }

    >:global(.MuiPagination-root) {
        margin: 20px auto;
    }
}