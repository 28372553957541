.main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: auto;
    min-height: 1000px;
    padding-top: 25px;

    &:not(.loading):after {
        content: "";
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        height: 600px;
        background-image: linear-gradient(to bottom, transparent, var(--WindowComponent-content-background));
        z-index: 999999;
    }

    >* {
        margin: 0px auto;
        width: auto;
        max-width: 800px;
    }

    :global(.MuiCircularProgress-circle) {
        color: var(--text-primary);
    }

    >.cart {
        --color-primary: 38, 50, 56;
        --color-secondary: 236, 239, 241;
        --color-dark: 33, 33, 33;
        --color-light: 96, 125, 139;
        --text-color: 236, 239, 241;

        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 200px;
        background-color: rgb(var(--color-primary));
        margin-bottom: 25px;
        border-radius: 10px;
        border: 2px solid rgba(0, 0, 0, .3);
        box-shadow: 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12), 0 5px 5px -3px rgba(0, 0, 0, .2);
        overflow: hidden;

        >.image {
            width: 200px;
            height: 200px;
            background-color: transparent;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            z-index: 1;

            >div {
                width: 70%;
                height: 70%;
                background-color: transparent;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                >svg {
                    width: 60%;
                    height: 60%;
                    fill: rgba(var(--text-color), 0.7);
                }

                >img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }

        >.info {
            flex: 1;
            background-color: rgb(var(--color-dark));
            height: 100%;
            min-height: 200px;
            margin-left: 50px;
            z-index: 0;
            color: rgb(var(--text-color));
            padding: 5px 30px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;

            &::before {
                content: "";
                position: absolute;
                top: 0px;
                left: -100px;
                width: 100px;
                bottom: 0px;
                background: linear-gradient(to right, transparent, rgb(var(--color-dark)));
            }

            * {
                font-family: "din-round", sans-serif !important;
            }

            >* {
                color: rgb(var(--text-color));
                margin: 10px 0px;
            }

            >p {
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
                opacity: .7;
                margin-top: 0px;
            }

            .progress {
                width: 100%;
                height: 25px;
                background-color: rgb(var(--color-light));
                border-radius: 14px;
                padding: 2px 40px 2px 2px;

                >.progressBar {
                    width: 0%;
                    min-width: 25px;
                    height: 100%;
                    background-color: rgb(var(--color-secondary));
                    transition: width .3s ease-in-out;
                    border-radius: 14px;

                    &::after {
                        content: "";
                        position: absolute;
                        top: 5px;
                        left: 10px;
                        right: 10px;
                        background-color: rgba(255, 255, 255, .1);
                        width: auto;
                        height: 5px;
                        border-radius: 2.5px;
                    }
                }

                >.progressText {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 14px;
                    color: rgb(var(--text-color));
                }

                >.progressIcon {
                    position: absolute;
                    top: -10px;
                    right: -10px;
                    width: 42.5px;
                    height: 42.5px;
                    background-color: rgb(var(--color-dark));
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    >svg {
                        width: 85%;
                        height: 85%;
                        fill: rgb(var(--color-light));
                    }
                }
            }
        }

        &.locked {
            >.image {
                opacity: .6;
                filter: grayscale(100%);
            }

            >.info {
                opacity: .5;

                .btn {
                    >button {
                        cursor: auto;
                        pointer-events: none;

                        &:hover {
                            opacity: 1;
                        }

                        &:active {
                            border-bottom-width: 4px;
                        }
                    }
                }
            }
        }

        @media screen and (max-width: 800px) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-height: 400px;

            >.image {
                width: 100%;
                height: 300px;
            }

            >.info {
                margin-left: 0px;
                align-items: center;
                text-align: center;
                padding: 5px 25px;
                width: 100%;

                &::before {
                    left: 0px;
                    top: -100px;
                    bottom: -100px;
                    width: 100%;
                    height: 100px;
                    background: linear-gradient(to bottom, transparent, rgb(var(--color-dark)));
                }

                .progress {
                    margin-top: 25px;
                }
            }

        }
    }
}