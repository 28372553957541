.centralizar-tela2{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 17px;
}
.centralizar-tela2 .bordas-bg-margem-div-btn{
    background-color: var(--Tela2-background);
    padding: 20px;
    width:182px;
    border-radius:10px;
}
.centralizar-tela2 .bordas-bg-margem-row-div-input{
    
    width: 180px;
}

.centralizar-tela2 .flex-row-tela2{
    display: flex;
    flex-direction: row;
}

.centralizar-tela2 .bg-box-tela2{
    background-color: var(--Tela2-background);
    border-radius:5px 5px 0px 0px; 
}

.centralizar-tela2 #porcentagem-variacao{
    color: var(--text-primary);
    font-size: 12px;
}

.centralizar-tela2 .bordas-box-tela2{
    border: none;
    border-radius: 6px;
}
.centralizar-tela2 .margem-box-tela2{
    margin-top: 0.6%;
    margin-bottom: 0.6%;
}

.centralizar-tela2 .margem-top-box-tela2{
    margin-top: 0.6%;
}

.centralizar-tela2 .conteudo-tela2{
    justify-content: space-evenly;
    width: 85%;
    max-height: 110px;
}

.centralizar-tela2 .conteudo-tela2 h1{
    font-size: 15px;
    color: var(--text-primary);
    text-align: center;
}

.centralizar-tela2 .conteudo-tela2 p{
    font-size: 10px;
    color: var(--text-primary);
}

.centralizar-tela2 .valor-box-tela2{
    flex-wrap: wrap;
    font-size: 12px;
    color: var(--text-primary);
    width: 84%;
    text-align: center;
}

.centralizar-tela2 .botoes-tela2{
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 85%;
}

.centralizar-tela2 .div-input-tela2{
    padding-left: 1%;
    padding-right: 1%;
    max-height: 52px;
}


.centralizar-tela2 .div-input-tela2 p{
    color: var(--text-primary);
    font-size: 12px;
}

.centralizar-tela2 #btn-comprarBtc-tela2{
    width: 70%;
    text-align: center;
}

.centralizar-tela2 Button{
    max-height: 30px;
}

.centralizar-tela2 Button p{
    font-size: 12px;
}


