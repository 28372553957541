.Marquee-root {
    overflow-x: hidden !important;
    display: flex !important;
    flex-direction: row !important;
    position: relative;
    width: 100%;
}

.Marquee-root:hover > div {
    animation-play-state: var(--pauseOnHover);
}

.Marquee-root:active > div {
    animation-play-state: var(--pauseOnClick);
}

.Marquee-root > div {
    flex: 0 0 auto;
    min-width: var(--min-width);
    z-index: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    animation: MarqueeScroll var(--duration) linear var(--delay) var(--loops);
    animation-direction: var(--direction);
}

@keyframes MarqueeScroll {
    0% {
        transform: translate(0);
    }
    to {
        transform: translate(-100%);
    }
}
