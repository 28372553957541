.root {
    padding: 5px;

    .loading {
        position: absolute;
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        background-color: rgba(0, 0, 0, .5);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        z-index: 9;

        :global(.MuiCircularProgress-root) {
            color: #fafafa !important;
            opacity: 1 !important;
        }
    }

    .select-swap-convert {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-bottom: 25px;
        align-items: center;
        user-select: none;
        z-index: 1;

        >div.input-arrow-icon {
            margin-inline-start: -10px;
            margin-inline-end: -10px;
            margin-top: 0px;
            margin-bottom: 0px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            background-color: var(--background-paper);
            background-image: linear-gradient(var(--action-selected), var(--action-selected));
            height: 30px;
            width: 30px;
            border-radius: 50%;
            border: 2px solid var(--action-hover);
            transition: border-color 0.1s ease-in-out;
            z-index: 1;

            >svg {
                fill: var(--text-primary);
                height: 20px;
                width: 20px;
            }
        }

        >div:not(.input-arrow-icon) {
            display: flex;
            flex-direction: column;
            background: var(--action-hover);
            border: 2px solid var(--action-hover);
            border-radius: 10px;
            flex: 1;
            max-width: 50%;
            height: 100%;
            padding: 10px 20px;
            cursor: pointer;
            user-select: none;

            &.active {
                border-color: var(--primary-main);
            }

            &:hover {
                background: var(--action-focus);
            }

            >span {
                font-size: 16px;
            }

            >div {
                display: flex;
                flex-direction: row;
                align-items: center;
                overflow: hidden;

                >div:first-child {
                    background-color: var(--action-hover);
                    background-size: 50% !important;
                    border-radius: 50%;
                    min-width: 40px !important;
                    min-height: 40px !important;
                }

                >div:last-child {
                    position: relative;

                    >h6 {
                        margin: 0px;
                        font-size: 18px;
                        line-height: 20px;
                    }

                    >div {
                        margin: 0px;
                        font-size: 10px;
                        line-height: 12px;
                        opacity: .6;
                        white-space: nowrap;
                        max-width: 100%;
                    }
                }
            }
        }

        &.column {
            flex-direction: column;
            border: solid 1px var(---divider);
            background-color: rgba(0, 0, 0, .01);
            border-radius: 10px;
            padding: 5px;

            >div.input-arrow-icon {
                margin-top: -10px;
                margin-bottom: -10px;

                >svg {
                    transform: rotate(90deg);
                }
            }

            >div:not(.input-arrow-icon) {
                width: 100%;
                max-width: 100% !important;
                flex: 1;
            }
        }
    }

    .input-swap-convert {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 15px;
        border: solid 1px var(---divider);
        background-color: rgba(0, 0, 0, .01);
        border-radius: 10px;
        overflow: hidden;
        padding: 0px 5px;

        .input-base {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            border-radius: 0px;
            width: 100%;
            border-radius: 10px;
            background-color: var(--action-hover);
            border: 2px solid var(--action-hover);
            margin: 5px 0px;

            >.input-symbol {
                margin: 10px 15px 5px 10px;
                height: 60px;
                width: 60px;
            }

            >.input-base-by {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 5px 0px 0px;

                > :global(.MuiInputBase-root) {
                    flex: 1;
                    padding-right: 20px;

                    input {
                        margin: 0px;
                        font-size: 25px;
                    }

                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }

                    input[type=number] {
                        -moz-appearance: textfield;
                    }
                }
            }

            >p.balance {
                font-style: italic;
                opacity: .8;
                width: 100%;
                padding: 0px 15px 4px;
            }
        }
    }

    >.description {
        margin: 0px;
        width: 100%;
        text-align: right;
        opacity: .8;

        >* {
            font-size: 14px;
        }
    }

    >.trade-button {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-evenly;
        margin: 15px auto 0px;
        padding: 5px 0px;
        max-width: 350px;
    }
}