
.sorteio-um{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 35px;
    border-radius:10px;

}
.sorteiodois{
    background-color: var(--Tela7-background);
    border-radius:10px;
    padding:20px;
    color: var(--text-primary);
}
.sorteiotres{
    background-color: var(--Tela7-background);
    border-radius:10px;
    padding:20px;
    color: var(--text-primary);
}
 .mover-griD-froM-scren-Ttwo {
    margin-top: 20px;
    margin-left: 7px;
    margin-right: 7px;
}
.RankingPas {
    display: flex;
    justify-content: space-between;
    margin-top: -16px;
    background-color: var(--Tela7-background);
    border-radius: 5px 5px 0px 0px;
}
.RankingPasone{ 
    margin-top: -5px;
    border-radius:1px;      
}
.Sorteio{
    margin-top: -5px;
    border-radius:1px;
}
.sorteio-one{
    margin-top: -50px;
    margin-right: -5px;
    padding:20px;
    
}
.rank-one{
    
    display: flex;
    flex-direction: row;
    justify-content:space-around;
}
.together{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 1px;
    border-radius:10px;
}
.togethertres{
    padding:20px;
    color: var(--text-primary);
}
.togetherquatro{
    padding:20px;
    color: rgb(0, 0, 0);
}
.RankingPasone{ 
    margin-top: -5px;
    border-radius:1px;      
}
.RankingPasone:hover{
    border-radius:10px 0px 0px 0px; 
    background-color:rgba(255, 255, 255, 0.2)!important;
}
.RankingPasone button{
    background-color:transparent !important;
}
.Sorteio{ 
    margin-top: -5px;
    border-radius:1px;      
}
.Sorteio:hover{
    border-radius:0px 10px 0px 0px; 
    background-color:rgba(255, 255, 255, 0.2)!important;
}
.Sorteio button{
    background-color:transparent !important;
}
