.main{
    width: 100%;
    height: 100%;

    &.initial{
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--text-primary);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: transparent;

        &::before{
            content: "";
            position: absolute;
            width: auto;
            height: auto;
            top: 0px;
            left: 0px;
            bottom: 0px;
            right: 0px;
            background: radial-gradient(circle, var(--WindowComponent-background) 10%, transparent 100%);
        }

        > :global(.MuiButton-root){
            overflow: hidden;
            
            &::before{
                content: "";
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                width: auto;
                height: auto;
                background-image: linear-gradient(35deg, transparent 30%, rgba(238, 238, 238, .2), transparent 70%);
                background-repeat: repeat-x;
                background-size: 200% 100%;
                animation: glowing 5s linear infinite;

                @keyframes glowing {
                    0% {
                        background-position: 200% 0;
                    }
                    100% {
                        background-position: 0% 0;
                    }
                }
            }
        }
    }
}