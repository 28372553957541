.root {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-family: "din-round", sans-serif !important;
    overflow: hidden;

    >:global(.MuiCircularProgress-root) {
        color: var(--text-primary);
        opacity: .5 !important;
    }

    .emblem {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -200px;
        margin-left: -200px;
        width: 400px;
        height: 400px;
        scale: 1;
        transition: top 1s, left 1s, scale 1s;

        .before-emblem-img,
        .after-emblem-img {
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -200px;
            margin-top: -200px;
            width: 400px;
            height: 400px;
            opacity: 0;
            scale: 2;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            transform-origin: center;
            transition: opacity .3s, scale .3s;

            &::before {
                content: "";
                position: absolute;
                top: -50%;
                left: -50%;
                width: 200%;
                height: 200%;
                background: radial-gradient(transparent, var(--text-primary));
                border-radius: 50%;
                opacity: .2;
                z-index: -1;
                scale: .2;
                transition: opacity .3s, scale .3s;
            }
        }

        .bar-xp {
            position: absolute;
            left: 50%;
            bottom: 20px;
            margin-left: -200px;
            translate: 0 -20px;
            width: 400px;
            height: 100px;
            opacity: 0;
            transition: bottom .5s, opacity .5s;

            >svg {
                width: 100%;
                height: 100%;

                >path {
                    fill: none;
                    stroke-linecap: round;
                    stroke-miterlimit: 10;
                    stroke-dasharray: 149.23;
                    stroke-dashoffset: 0;

                    &:nth-child(1) {
                        stroke: rgba(0, 0, 0, .2);
                        stroke-width: 8px;
                        opacity: .8;
                    }

                    &:nth-child(2) {
                        stroke: var(--info-main);
                        stroke-width: 3px;
                        opacity: .8;
                    }

                    &:nth-child(3) {
                        stroke: var(--primary-main);
                        stroke-width: 3px;
                    }

                    &:nth-child(4) {
                        stroke: var(--error-dark);
                        stroke-width: 3px;
                    }
                }

                >text {
                    font-size: 10px;
                    fill: var(--text-primary);
                    dominant-baseline: middle;
                    opacity: .5;
                }
            }
        }
    }

    .achievements-items {
        position: absolute;
        right: 50%;
        bottom: 150px;
        translate: 50% 50%;
        width: auto;
        max-width: 90%;
        height: auto;
        border-radius: 10px;
        opacity: 0;
        transition: opacity 1s, bottom 1s;

        &::before,
        &::after {
            content: "";
            position: absolute;
            top: 0;
            width: 20px;
            height: 100%;
            pointer-events: none;
            z-index: 2;
        }

        &::before {
            left: -1px;
            background: linear-gradient(to left, transparent, var(--WindowComponent-content-background));
        }

        &::after {
            right: -1px;
            background: linear-gradient(to right, transparent, var(--WindowComponent-content-background));
        }

        >.division {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 20px;
            flex-wrap: nowrap;
            position: absolute;
            color: var(--text-primary);
            font-size: 14px;
            opacity: .7;

            >div {

                &:first-child,
                &:last-child {
                    width: 10px;
                    height: 10px;
                    background-color: var(--text-primary);
                    border-radius: 50%;
                    opacity: .3;

                    &::before {
                        content: "";
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        width: 200px;
                        height: 4px;
                        background-image: linear-gradient(to left, var(--text-primary), transparent);
                        transform: translate(-100%, -50%);
                    }
                }

                &:last-child {
                    &::before {
                        background-image: linear-gradient(to right, var(--text-primary), transparent);
                        transform: translate(0%, -50%);
                    }
                }
            }
        }

        >.content {
            width: 100%;
            padding: 20px 10px;
            white-space: nowrap;
            text-align: center;
            overflow-x: auto;
            z-index: 1;

            >div {
                --color: #00838f;
                width: 150px;
                min-width: 100px;
                padding: 5px 2px 2px 2px;
                background-color: var(--color);
                border-radius: 10px;
                text-align: center;
                display: inline-flex;
                flex-direction: column;
                white-space: wrap;
                margin: 0px 10px;
                border: 1px solid rgba(0, 0, 0, .1);

                >p {
                    color: #f5f5f5;
                    font-size: 12px;
                    margin: 0px 0px 5px 0px;
                    color: rgba(0, 0, 0, .5);
                }

                >.content {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 5px;
                    background-color: var(--WindowComponent-content-background);
                    border-radius: 10px;
                    color: var(--text-primary);
                    padding: 0px 10px;
                    border: 1px solid rgba(0, 0, 0, .1);
                }
            }
        }
    }

    :global(.MuiTypography-caption) {
        position: absolute;
        bottom: 10px;
        width: 90%;
        max-width: 200px;
        text-align: center;
        color: var(--text-primary);
        font-weight: bold;
        opacity: .5;
        opacity: 0;
        transition: opacity 1s;
    }

    &.stage-1,
    &.stage-2,
    &.stage-3 {
        >:global(.MuiCircularProgress-root) {
            opacity: 0 !important;
        }

        .bar-xp {
            bottom: 0px;
            opacity: 1;
        }
    }

    &.stage-1 {
        .emblem {
            .before-emblem-img {
                opacity: 1;
                scale: 1;

                &::before {
                    scale: 1;
                    opacity: 0;
                }
            }
        }
    }

    &.stage-2 {
        .emblem {
            .before-emblem-img {
                opacity: 0;
                scale: .2;

                &::before {
                    scale: 1;
                    opacity: 0;
                }
            }

            .after-emblem-img {
                opacity: 1;
                scale: 1;

                &::before {
                    scale: 1;
                    opacity: 0;
                }
            }
        }
    }

    &.stage-3 {
        .emblem {
            top: 50%;
            left: 20%;
            scale: .8;
        }

        .achievements-items {
            opacity: 1;
            bottom: 50%;
            right: 25%;
            translate: 50 50%;
            max-width: 50%;

            >.division {
                rotate: -90deg;
                left: -30px;
                top: 0%;
                translate: -50% 50%;
                scale: .7;
            }
        }

        :global(.MuiTypography-caption) {
            opacity: .7;
        }
    }

    @media screen and (min-height: 580px) {
        &.stage-3 {
            .emblem {
                top: 25%;
                left: 50%;
                scale: 1;
            }

            .achievements-items {
                right: 50%;
                bottom: 150px;
                translate: 50% 50%;
                max-width: 90%;

                >.division {
                    rotate: 0deg;
                    top: -30px;
                    left: 50%;
                    transform: translateX(0%);
                    scale: 1;
                }
            }
        }
    }
}