.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    padding: 10px 10px 20px 10px;

    >.picture {
        display: flex;
        flex-direction: row;
        justify-content: center;
        padding-left: 20px;

        >div {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: rgba(0, 0, 0, .05);
            border: 2px solid rgba(0, 0, 0, .1);
            border-radius: 50%;
            width: 100px;
            height: 100px;
            margin-left: -20px;
            overflow: hidden;

            &:last-child::before {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, .1);
                border-radius: 50%;
                position: absolute;
                top: 0;
                right: calc(100% - 17px);
            }

            >svg {
                width: 50%;
                height: 50%;
            }
        }
    }

    >.title {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        >* {
            font-weight: bold;
        }

        >svg {
            width: 24px;
            height: 24px;
            opacity: .6;
            margin: 0px 10px;
        }
    }
}

.content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 95%;
    max-width: 500px;
    min-height: 150px;
    margin: 0px auto;
    gap: 10px;

    .loading {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        width: 100%;
        min-height: 100px;
        padding: 15px 0px;
        opacity: .7;
    }

    .partners {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 10px;
        width: 100%;
        min-height: 100px;
        padding: 15px 0px;

        >.item {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            padding: 10px;
            border: 2px solid rgba(0, 0, 0, .1);
            border-radius: 10px;
            gap: 15px;
            cursor: pointer;
            overflow: hidden;

            &::before {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                background-color: rgba(0, 0, 0, 0);
                position: absolute;
                top: 0;
                left: 0;
                opacity: .05;
            }

            >.icon {
                display: flex;
                justify-content: center;
                align-items: center;
                border: 2px solid rgba(0, 0, 0, .1);
                border-radius: 50%;
                width: 60px;
                height: 60px;
                overflow: hidden;

                &::before {
                    content: '';
                    display: block;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, .5);
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    opacity: .1;
                }

                >svg {
                    width: 55%;
                    height: 55%;
                }
            }

            &.selected {
                border-color: var(--primary-main) !important;

                &::before {
                    background-color: var(--primary-main);
                }

                >.icon {
                    border-color: var(--primary-main) !important;
                }
            }

            &:hover {
                background-color: rgba(0, 0, 0, .05);
                border-color: rgba(0, 0, 0, .2);

                &::before {
                    background-color: rgba(0, 0, 0, .5);
                }

                >.icon {
                    border-color: rgba(0, 0, 0, .2);

                    &::before {
                        background-color: rgba(0, 0, 0, .5);
                    }
                }
            }

            >.info {
                flex: 1;

                >:global(.MuiTypography-h5) {
                    font-weight: bold;
                }
            }
        }
    }
}