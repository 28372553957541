.InteractiveForm-Root{
  position: relative;
  border-bottom: 2px solid;
  border-color: rgba(0, 0, 0, .4);
  padding: 0px 0.5em 1px 0.5em;
  cursor: text;
}

.InteractiveForm-Root *{
  white-space: pre-wrap;
  transition: 0.3s border ease-in-out;
  position: relative;
}

.InteractiveForm-Root .InteractiveForm-Input {
  outline: 0px solid transparent;
}

.InteractiveForm-Root .InteractiveForm-Input.InteractiveForm-type-password{
  -webkit-text-security: disc;
}

.InteractiveForm-Root .InteractiveForm-Input.blink-caret:focus::before{
  content: "";
  overflow: hidden;
  border-right: solid currentColor;
  border-right-width: .04em;
  white-space: nowrap;
  margin: 0 auto;
  letter-spacing: .15em;
  animation: InteractiveForm-blink-caret 1s step-end infinite;
}

@keyframes InteractiveForm-blink-caret {
  from, to { border-color: transparent }
  50% { border-color: currentColor; }
}

.InteractiveForm-Root:focus-within{
  border-color: #2196f3;
}

/* .InteractiveForm-Input::after {
  content: "";
  height: 2px;
  width: 0px;
  position: absolute;
  top: 100%;
  left: 0px;
  background-color: #2196f3;
  transition: 0.3s width ease-in-out;
}

.InteractiveForm-Input:focus::after {
  width: 100%;
} */
