.filters {
    display: flex;
    margin-bottom: 10px;

    &:last-child {
        margin-bottom: 0px;
    }

    >.title {
        font-size: 14px;
        font-weight: 700;
        color: var(--text-secondary);
        margin-right: 10px;
        margin-top: 2px;
    }

    >.options {
        display: flex;
        align-items: center;
        gap: 10px;
        flex-wrap: wrap;
        border: 2px solid var(--TableCustom-border);
        flex: 1;
        padding: 5px;
        border-radius: 10px;
    }

    @media screen and (max-width: 700px) {
        flex-direction: column;

        >.title {
            margin-bottom: 10px;
        }
    }
}