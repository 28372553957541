.warning {
    display: flex;
    align-items: center;
    padding: 5px 15px;
    background-color: var(--warning-main);
    border: 2px solid var(--warning-main);
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
    color: var(--text-primary);
    border-radius: 10px;
    color: #ffffff;
    font-weight: bold;
    margin: 0px 5px 10px 5px;

    svg {
        margin-right: 10px;
    }
}

.indicators {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 10px 0px;

    >div {
        --color: rgba(0, 0, 0, .1);
        flex: 1 0 calc(25% - 20px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 10px 15px;
        border-radius: 10px;
        background-color: transparent;
        background-color: var(--color);
        background-image: linear-gradient(var(--box-overlaid), var(--box-overlaid));
        border: 2px solid var(--color);
        margin: 5px;
        color: var(--text-primary);
        // color: #ffffff;
        user-select: text;

        &.primary,
        &.secondary,
        &.success,
        &.warning {
            // background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
            color: #ffffff;
        }

        &.primary {
            --color: var(--Chart-color-primary);
        }

        &.secondary {
            --color: var(--Chart-color-secondary);
        }

        &.success {
            --color: var(--success-main);
        }

        &.warning {
            --color: var(--warning-main);
        }

        .title {
            font-size: 12px;
            font-weight: bold;
        }

        .value {
            font-size: 30px;
            font-weight: bold;
            white-space: nowrap;

            >svg {
                opacity: 1;
                animation: blink 1.5s ease-in-out infinite alternate;
                margin-right: 10px;

                @keyframes blink {
                    0% {
                        opacity: 1;
                    }

                    100% {
                        opacity: .1;
                        color: #fafafa;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 600px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;

        >div {
            width: 100%;
        }
    }
}

.main {
    padding: 20px;
    width: 100%;

    .movement-status-draw,
    .movement-status-rejected,
    .movement-status-approved,
    .movement-status-pending {
        text-align: right;

        >div {
            font-weight: bold;
            font-size: 12px;
            padding: 4px 0px;
            border-radius: 5px;
            width: 100px;
            text-align: center;
            background-color: rgba(255, 255, 255, 0.2);
            color: #ffffff;
        }
    }

    .movement-status-pending>div {
        background-color: #ef6c00;
    }

    .movement-status-draw>div {
        background-color: #ad1457;
    }

    .movement-status-approved>div {
        background-color: #4caf50;
    }

    .movement-status-rejected>div {
        background-color: #f44336;
    }

    .movement-method {
        display: flex;
        align-items: center;
        flex-direction: row;
        color: var(--text-primary);

        >svg {
            margin-right: 10px;
        }

        >span {
            font-size: 12px;
        }
    }

    @media screen and (max-width: 900px) {
        padding-bottom: 100px;
    }
}