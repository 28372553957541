.main {
    .Widget-root {
        margin-top: 15px;
        margin-bottom: 25px;
    }

    .Widget {
        // backdrop-filter: blur(0px);
        border-radius: 10px;
    }

    :global(.react-grid-item.react-draggable-dragging) {
        // backdrop-filter: blur(30px);
        background-color: var(--WindowComponent-content-background);

        .Widget-component {
            box-shadow: 0 16px 24px 2px rgba(0, 0, 0, .14), 0 6px 30px 5px rgba(0, 0, 0, .12), 0 8px 10px -5px rgba(0, 0, 0, .2);
        }
    }

    :global(.react-grid-item.react-grid-placeholder) {
        background: var(--primary-dark);
        border: 2px solid rgba(0, 0, 0, .5);
        border-radius: 10px;
    }

    .Widget-component {
        position: relative;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        border: 2px solid var(--WindowComponent-content-border-color);
        background-color: var(--WindowComponent-content-border-color);

        &>div.Widget-title {
            display: flex;
            flex-direction: row;
            color: var(--Widget-title);
            padding: 8px 10px 8px 12px;
            cursor: move;
            border-bottom: 2px solid var(--WindowComponent-content-border-color);
            background-color: rgba(0, 0, 0, .05);

            &>div.Widget-icon {
                width: 20px;
                height: 20px;
                display: flex;
                align-items: center;
                justify-content: center;

                &>svg {
                    width: 85%;
                    height: 85%;
                }
            }

            &>div.Widget-label {
                display: flex;
                align-items: center;
                justify-content: start;
                flex: 1;
                padding: 0px 5px;
                font-size: 14px;
                white-space: nowrap;
                overflow: hidden;
            }

            &>div.Widget-actions {
                display: none;
            }
        }

        &>div.Widget-content {
            flex: 1;
            overflow-x: hidden;
            overflow-y: auto;

            div.Widget-loading {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 100%;
                opacity: 0.4;
            }

            div.Widget-error,
            div.Widget-deny {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                min-height: 100%;
                padding: 10px 15px 10px 15px;
                color: var(--Widget-aviso);

                &>svg {
                    width: 45px;
                    min-height: 45px;
                }

                &>.span {
                    font-size: 14px;
                    margin: 15px auto 0px auto;
                    color: var(--text-primary);
                    background: rgba(0, 0, 0, .05);
                    border: 1px solid rgba(0, 0, 0, .1);
                    padding: 5px 15px;
                    border-radius: 5px;
                    text-align: center;
                }
            }
        }
    }

    :global(.react-grid-item)> :global(.react-resizable-handle) {
        background: transparent;

        &::after {
            border-color: var(--text-primary);
            border-bottom-right-radius: 5px;
        }
    }
}