.root {
  .loading {
    opacity: 0.4;
    justify-content: center;
    align-items: center;
    margin: 140px 0px;

    :global(.MuiCircularProgress-root) {
      color: var(--text-primary);
      width: 30px !important;
      height: 30px !important;
    }
  }

  .switch {
    border-radius: 14px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;
  }
}
