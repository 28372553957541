.header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    --color: rgba(0, 0, 0, .3);
    --text-color: 255, 255, 255;

    .nft-cart {
        width: 300px;
        height: 300px;
        background-color: var(--color);
        border: solid 2px var(--color);
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        box-shadow: 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12), 0 5px 5px -3px rgba(0, 0, 0, .2);

        >svg {
            width: 100px;
            height: 100px;
            fill: #fafafa;
            opacity: .6;
        }
    }

    .nft-cart-info {
        flex: 1;
        max-width: 600px;
        margin: 0px 20px;

        .code {
            text-align: center;
            font: 16px Inconsolata, monospace;
            letter-spacing: 2px;
            color: var(--text-primary);
        }

        .title {
            text-transform: uppercase;
            padding: 5px 15px;
            margin: 10px 10px 0px 10px;
            border: 1px solid rgba(var(--text-primary-rgb), .3);
            background: radial-gradient(circle, rgba(var(--text-primary-rgb), .3) 41%, rgba(var(--text-primary-rgb), .1) 100%);
            border-radius: 10px 10px 0px 0px;
            font-size: 18px;
            text-align: center;
            color: var(--text-primary);
            font-weight: bold;
            letter-spacing: 2px;
            min-height: 30px;
        }

        .description {
            padding: 10px 15px;
            margin: 0px 10px 25px 10px;
            border: 1px solid rgba(var(--text-primary-rgb), .3);
            border-top-width: 0px;
            border-radius: 0px 0px 10px 10px;

            :global(.MuiTypography-body1) {
                font-style: italic;
            }

            :global(.MuiTypography-body2) {
                padding: 0px 15px;
            }
        }

        .user-info {
            display: flex;
            align-items: center;
            border: 2px dashed rgba(var(--text-primary-rgb), .3);
            background-color: rgba(var(--text-primary-rgb), .05);
            border-radius: 50px;
            padding: 5px;
            margin: 0px 10px 25px 10px;
            overflow: hidden;

            .picture {
                width: 40px;
                min-width: 40px;
                height: 40px;
                min-height: 40px;
                background-color: rgba(0, 0, 0, .2);
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                border-radius: 50%;
            }

            .label {
                padding: 0px 15px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                flex: 1;
                flex: 1;
                width: auto;
                overflow: hidden;

                * {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    line-height: 1;
                    opacity: .8;
                    max-width: 100%;
                }

                :global(.MuiTypography-body1) {
                    line-height: 1.2;
                    opacity: 1;
                }
            }
        }
    }

    @media screen and (max-width: 800px) {
        flex-direction: column;

        .nft-cart {
            width: 300px;
            height: 300px;
        }

        .nft-cart-info {
            margin-top: 30px;
            width: 100%;
            max-width: 100%;
        }
    }

    @media screen and (max-width: 500px) {
        .nft-cart {
            width: 250px;
            height: 250px;

            >svg {
                width: 80px;
                height: 80px;
            }
        }
    }

    @media screen and (max-width: 300px) {
        .nft-cart {
            width: 150px;
            height: 150px;
        }
    }
}

.actions-bar {
    width: 95%;
    max-width: 800px;
    height: 55px;
    padding: 5px 10px;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, .2);
    border: 2px solid rgba(0, 0, 0, .1);
    box-shadow: 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12), 0 5px 5px -3px rgba(0, 0, 0, .2);
    z-index: 2;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
}

.nft-catalog {
    padding: 15px;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;

    .nft-card-price {
        padding: 4px 15px;
        background-color: var(--warning-light);
        border-radius: 20px;
        font-size: 12px;
        font-weight: bold;
        color: #212121;
        border: solid 2px rgba(0, 0, 0, .2);
        box-shadow: 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12), 0 5px 5px -3px rgba(0, 0, 0, .2);
    }

    >.nft-card {
        --color: #212121;
        --text-color: #fafafa;
        --color-rgb: 0, 0, 0;
        background-color: var(--color);
        border-radius: 10px;
        width: 180px;
        height: 180px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin: 20px 15px;
        box-shadow: 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12), 0 5px 5px -3px rgba(0, 0, 0, .2);
        cursor: pointer;
        transform-origin: center;

        &.mark {
            border: solid 2px var(--warning-light);
        }

        &:hover:not(.spacing) {
            transform: scale(1.1);
            transition: transform .2s;
            border: solid 2px var(--color);

            >.nft-card-title {
                border-radius: 0px 0px 5px 5px;
            }
        }

        &.spacing {
            height: 0px;
            background: transparent;
            opacity: 0;
            box-shadow: none;
            margin-top: 0px;
            margin-bottom: 0px;
        }

        >.ribbon {
            width: 150px;
            height: 150px;
            overflow: hidden;
            position: absolute;
            z-index: 1;
            transform: scale(.5) translate(-10px, -10px);
            transform-origin: top left;

            &::before,
            &::after {
                position: absolute;
                z-index: -1;
                content: '';
                display: block;
                border: 5px solid #2980b9;
                border-top-color: transparent;
                border-left-color: transparent;
            }

            &::before {
                top: 0;
                right: 0;
            }

            &::after {
                bottom: 0;
                left: 0;
            }

            span {
                position: absolute;
                display: block;
                width: 225px;
                padding: 15px 0;
                background-color: #3498db;
                box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
                color: #fff;
                font: 700 18px/1 'Lato', sans-serif;
                text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
                text-transform: uppercase;
                text-align: center;
                right: -25px;
                top: 30px;
                transform: rotate(-45deg);
            }
        }

        >.nft-card-title {
            position: absolute;
            bottom: 0px;
            width: 100%;
            padding: 40px 10px 5px 10px;
            background-image: linear-gradient(transparent, var(--color));
            font-size: 14px;
            font-weight: bold;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: var(--text-color);
            border-radius: 0px 0px 10px 10px;
        }
    }
}