.statistic-resume {
    display: flex;
    flex-direction: row;
    align-items: flex-end;

    >.balance {
        flex: 1;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-x: auto;
        overflow-y: hidden;
        padding: 15px 25px;
        z-index: 1;
        max-width: 100%;
        width: 100%;
        min-height: 250px;

        >.balance-container {
            flex: 0 0 auto;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: center;
            width: auto;
            min-width: 100%;
            gap: 15px;

            >.balance-item {
                background-color: rgba(0, 0, 0, .05);
                border: 2px solid rgba(0, 0, 0, .1);
                border-radius: 10px;
                width: min-content;
                min-width: 250px;
                padding-bottom: 10px;
                white-space: nowrap;
                overflow: hidden;
                flex: 0 0 auto;

                &::before {
                    content: attr(data-amount);
                    position: absolute;
                    bottom: 0px;
                    right: 10px;
                    font-size: 70px;
                    color: rgba(0, 0, 0, .05);
                    text-transform: uppercase;
                    font-family: "din-round", sans-serif !important;
                }

                * {
                    white-space: nowrap;
                }

                >* {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    justify-content: space-between;
                    align-items: center;
                    gap: 15px;

                    >* {
                        flex: 0 0 auto;
                    }
                }

                >:global(.MuiTypography-subtitle1) {
                    font-size: 20px;
                    font-weight: 700;
                    background-color: rgba(0, 0, 0, .1);
                    padding: 0px 5px 0px 15px;
                    margin-bottom: 10px;

                    svg {
                        opacity: 1;
                        animation: blink 1.5s ease-in-out infinite alternate;

                        @keyframes blink {
                            0% {
                                opacity: 1;
                            }

                            100% {
                                opacity: .1;
                            }
                        }
                    }
                }

                >:global(.MuiTypography-body1) {
                    padding: 2px 15px;
                }
            }

            >.balance-add-btn {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                >div {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    color: var(--text-primary);
                    background-color: rgba(0, 0, 0, .05);
                    border: 2px solid rgba(0, 0, 0, .1);
                    border-radius: 50px;
                    padding: 5px 20px 5px 10px;
                    height: auto;
                    font-family: "din-round", sans-serif !important;
                    cursor: pointer;
                    transition: all .1s ease-in-out;

                    &:hover {
                        background-color: rgba(0, 0, 0, .2);
                        border: 2px solid rgba(0, 0, 0, .2);
                    }

                    &:focus,
                    &:active {
                        background-color: rgba(0, 0, 0, .1);
                        border: 2px solid rgba(0, 0, 0, .4);
                    }
                }
            }
        }
    }

    >.chart {
        position: absolute;
        bottom: 0px;
        right: 0px;
        height: 100%;
        width: auto;
        overflow: hidden;
        z-index: 0;
        opacity: .5;

        >svg {
            width: auto;
            min-height: 100%;
        }
    }

    @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;

        >.balance {
            >.balance-container {
                >.balance-item {
                    min-width: 200px;
                }
            }
        }
    }
}

.staking-cards {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 25px;
    padding: 20px 0px 40px 0px;

    .staking-card-header {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-height: 100%;
        flex: 1;
        overflow-y: auto;

        @media screen and (max-width: 600px) {
            flex-direction: row;
            flex-wrap: wrap;
            padding: 15px 0px;

            >* {
                flex: 1;
                flex-basis: 50%;
            }
        }
    }
}

.main {
    padding: 0px;
    margin: 0px;

    >* {
        width: 100%;
    }
}