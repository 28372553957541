
 .historico-operacao{
     display: flex;
     flex-direction: row;
     justify-content: center;
     height:30px;
 }

.todOs-dos{
    padding-left: 70px;
    background-color: var(--Tela6-background);
    height:30px;
    width:168px;
    border-radius:0px 0px 5px 0px;
}

.cEx-dos button{
    background-color:transparent !important;
}



.mover-griD-froM-scren-TwO{
    margin-top: 20px;
    display: flex;
    flex-direction:row;
    justify-content:center;
 }
 .VaR{
    display: flex;
    flex-direction: row;
    justify-content:center;
    margin-top:-70px;
    padding:2px;
    margin-bottom: 20px;
}
.VaRiacOes{
    display: flex;
    flex-direction: row;
    justify-content:space-around;
    margin-top: 50px;
    margin-left: 110px;
    padding:20px;
    margin-bottom: 20px;
}

.idenx-btc{ 
    margin-left:1px;     
}
.unindo-index{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.numero-index-btc-one{
  margin-top: 8px;
    font-size: 17px;
}

