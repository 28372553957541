.root {
  .main-content {
    padding-inline: 18px;
    border-radius: 8px;
    height: auto;
    text-overflow: ellipsis;
    overflow: hidden;
    background: linear-gradient(
      180deg,
      rgba(255, 152, 0, 0.3),
      transparent 50%
    );
  }
  .loading {
    opacity: 0.4;
    justify-content: center;
    align-items: center;
    margin: 140px 0px;

    .MuiCircularProgress-root {
      color: var(--text-primary);
      width: 30px !important;
      height: 30px !important;
    }
  }

  .user_title {
    padding-block: 18px;
  }

  .profile_image {
    border-radius: 50%;
    width: 120px;
  }

  .profile_header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
  }

  .profile_content {
    position: relative;
  }

  .profile_content_infos {
    display: flex;
    flex-basis: 33.33%;
    gap: 140px;
    justify-content: center;
    background-color: var(--Profile-background);
    padding-block: 50px;
    border-radius: 8px;
    border: 1px solid var(--Widget-border);
    width: 85%;
    margin: 0 auto;
  }

  .profile_content_image {
    z-index: 2;
    transform: translateY(15%);
  }

  .wallets {
    padding-top: 100px;
  }

  .main-content-header {
    display: flex;
    align-items: center;
    gap: 18px;
  }

  .main-content-header-arrow {
    color: white;
    cursor: pointer;
    margin-bottom: 10px;
  }

  .profile_info {
    width: 300px;
    height: 30px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .profile_content_infos span {
    font-weight: 700;
  }

  @media (max-width: 1700px) {
    .main-content-header {
      position: fixed;
      background: linear-gradient(
        180deg,
        rgba(255, 152, 0, 0.4),
        transparent 100%
      );
      width: 100%;
      z-index: 3;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .profile_content_infos {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 12px;
      width: 100%;
      padding-inline: 12px;
    }
    .profile_content_image {
      margin-top: 80px;
    }
  }
}
