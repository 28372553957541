:global(.MuiDialogContent-root):has(.main){
    padding: 0px;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    height: 100%;
    overflow: hidden;

    :global(.MuiBox-root){
        display: flex;
        flex-direction: column;
        max-height: 100%;
        height: 100%;
        overflow: hidden;
    }
}

.main{
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    max-height: 100%;
    height: 100%;
    overflow: hidden;

    .content{
        flex: 1 1 auto;
        overflow-y: auto;
        padding: 16px 24px;

        :global(.MuiAccordion-root){
            border: 0px;
            background: transparent !important;

            &:not(:last-child){
                border-bottom: 0;
            }

            &:before{
                display: none;
            }

            :global(.MuiAccordionSummary-root){
                background-color: rgba(0, 0, 0, .1);
                border: 2px solid rgba(0, 0, 0, .2);
                border-radius: 5px;
                flex-direction: row-reverse;

                :global(.MuiAccordionSummary-content){
                    margin-top: 0px !important;
                    margin-bottom: 0px !important;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    > *:first-child{
                        flex: 1;
                    }
                }

                &:global(.Mui-expanded){
                    border-bottom-left-radius: 0px;
                    border-bottom-right-radius: 0px;
                }

                :global(.MuiAccordionSummary-expandIconWrapper.Mui-expanded){
                    transform: rotate(90deg);
                }

                :global(.MuiAccordionSummary-content){
                    margin-left: 15px;
                }
            }

            :global(.MuiCollapse-root){
                overflow-x: auto;
                border: 2px solid rgba(0, 0, 0, .2);
                border-top-width: 0px;
                border-radius: 0px 0px 5px 5px;

                :global(.MuiAccordionDetails-root){
                    padding: 0px;
                }
            }
        }

        > .description,
        > .description-warning,
        > .description-alert{
            background-color: rgba(0, 0, 0, .1);
            padding: 10px 15px;
            margin: 20px 0px;
            border-radius: 5px;
            border: 2px solid rgba(0, 0, 0, .2);
            text-align: justify;
            font-size: 14px;
        }

        > .description-warning,
        > .description-alert{
            border-style: dashed;
        }

        > .description-warning{
            border-color: var(--warning-main);
            color: var(--warning-light);
        }

        > .description-alert{
            border-color: var(--info-main);
            color: var(--info-light);
        }
    }

    .footer{
        display: flex;
        flex-direction: row;
        border-top: 1px solid var(--action-focus);
        padding: 15px;

        > *:first-child{
            flex: 1;
        }
    }
}