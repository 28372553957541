.dialogPayment {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 300px;

    >.amount {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        min-height: 150px;
        padding: 15px 10px;

        > :global(.MuiTextField-root),
        > :global(.MuiSlider-root) {
            width: 95%;
            max-width: 350px;
        }

        > :global(.MuiTextField-root > .MuiInputBase-root) {

            &::before,
            &::after {
                display: none;
            }

            input {
                padding: 0px;
            }
        }

        > :global(.MuiTypography-body2) {
            color: var(--error-main);
            margin: 10px 0px;
        }
    }

    >.payment-code {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px 0px 0px;

        >* {
            margin-bottom: 25px;
        }

        >.payment-code-image {
            width: auto;
            background-color: rgba(255, 255, 255, 0.8);
            padding: 20px;
            border-radius: 5px;
            text-align: center;
        }

        >.payment-code-input {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
            gap: 0px;
            border: 2px solid rgba(0, 0, 0, .3);
            border-radius: 10px;
            padding: 0px;

            &::before {
                content: "";
                position: absolute;
                top: -16px;
                left: 50%;
                transform: translate(-50%, 0%);
                border-bottom: 15px solid rgba(0, 0, 0, .3);
                border-left: 30px solid transparent;
                border-right: 30px solid transparent;
                float: left;
            }

            >.payment-code-input-value {
                flex: 1 1 auto;
                text-wrap: nowrap;
                overflow-y: auto;
                -ms-overflow-style: none;
                scrollbar-width: none;
                background-color: rgba(0, 0, 0, .1);
                padding: 15px;
                border-radius: 10px 0px 0px 10px;
                border-right: 2px solid rgba(0, 0, 0, .2);
            }

            > :global(.MuiTextField-root) {
                flex: 1;
            }

            > :global(.MuiIconButton-root) {
                &:hover {
                    opacity: .7;
                }

                &:active {
                    opacity: .4;
                }
            }
        }
    }

    .select-currency {
        display: flex;
        flex-direction: row;
        background: var(--action-hover);
        border: 2px solid var(--action-hover);
        border-radius: 10px;
        flex: 1;
        width: 100%;
        user-select: none;
        align-items: center;
        overflow: hidden;

        .select-label {
            padding: 10px 20px;
            border-right: 2px solid var(--action-hover);
            margin-right: 2px;
        }

        .select-item {
            display: flex;
            flex-direction: row;
            cursor: pointer;
            align-items: center;
            overflow: hidden;
            padding: 10px 20px;
            flex: 1;

            &.active {
                border-color: var(--primary-main);
            }

            &:hover {
                background: var(--action-focus);
            }

            >div:first-child {
                background-color: var(--action-hover);
                background-size: 50% !important;
                border-radius: 50%;
                min-width: 40px !important;
                min-height: 40px !important;
            }

            >div:last-child {
                position: relative;

                >h6 {
                    margin: 0px;
                    font-size: 18px;
                    line-height: 20px;
                }

                >div {
                    margin: 0px;
                    font-size: 10px;
                    line-height: 12px;
                    opacity: .6;
                    white-space: nowrap;
                    max-width: 100%;
                }
            }
        }
    }

    &.resolved {
        .amount {
            >span {
                font-size: 40px;
                font-weight: bold;
            }
        }
    }

    &.color-red {

        .amount,
        .apply-color {
            color: var(--error-main) !important;
        }
    }

    &.color-warn {

        .amount,
        .apply-color {
            color: var(--warning-main) !important;
        }
    }

    &.color-success {

        .amount,
        .apply-color {
            color: var(--success-main) !important;
        }
    }

    >.payment-icon {
        display: flex;
        flex-direction: column;
        align-items: center;

        >svg {
            fill: var(--text-disabled);
            width: 100px;
            height: 100px;
            margin: 30px 0px;
        }
    }

    >.payment-whatsapp-info {
        color: var(--text-primary);
        padding: 10px 15px;
        background-color: rgba(0, 0, 0, .1);
        border: 2px dashed var(--text-disabled);
        border-radius: 10px;
        margin: 15px 0px;
        text-align: justify;
        font-size: 18px;
    }

    >.payment-unavailable {
        background-color: rgba(0, 0, 0, 0.05);
        border: 2px dashed var(--warning-main);
        padding: 10px 15px;
        color: var(--primary-main);
        margin: 15px 0px 25px 0px;
        border-radius: 10px;
    }

    >.description,
    >.payment-limit-view {
        background-color: rgba(0, 0, 0, .1);
        padding: 10px 15px;
        margin: 10px 0px;
        border-radius: 10px;
        border: 2px solid rgba(0, 0, 0, .2);
        text-align: justify;

        &.payment-limit-view-green {
            border-color: var(--success-light);
            color: var(--success-main);
        }

        &.payment-limit-view-warns,
        &.warns {
            border-color: var(--warning-light);
            color: var(--warning-main);
        }
    }

    >.paymentMethods {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0px auto 25px;
        width: 100%;
        max-width: 400px;

        >div {
            align-items: center;
            display: flex;
            flex-direction: column;
            margin: 10px 5px;
            width: 80px;
            cursor: pointer;

            &.paymentMethods-active {
                >.paymentMethods-logo {
                    border-color: var(--primary-main) !important;
                    opacity: 1;
                }

                >.paymentMethods-label {
                    opacity: 1;
                }
            }

            &:hover {
                >.paymentMethods-logo {
                    border-color: var(--action-active);
                    opacity: 1;
                }

                >.paymentMethods-label {
                    opacity: 1;
                }
            }

            >.paymentMethods-logo {
                align-items: center;
                display: flex;
                justify-content: center;
                height: 50px;
                width: 50px;
                border: 1.5px solid var(--action-disabled);
                border-radius: 50px;
                background-color: transparent;
                opacity: .5;
                transition: opacity .14s ease-in-out 0ms;

                >svg {
                    height: 60%;
                    width: 60%;
                }
            }

            >.paymentMethods-label {
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                margin-top: 10px;
                text-align: center;
                opacity: .3;
                transition: opacity .14s ease-in-out 0ms;
            }
        }
    }

    >.list-info,
    >.amount-costs {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border: 2px solid var(--action-disabled);
        background-color: rgba(0, 0, 0, .1);
        border-radius: 10px;

        >div {
            display: flex;
            flex-direction: row;
            width: 100%;
            padding: 15px;
            border: 0px solid var(--action-disabled);
            border-top-width: 1px;

            &:first-child {
                border-top-width: 0px;
            }

            >.label {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                margin-right: 20px;
                flex: 1;
            }

            >.value {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: center;
                text-align: right;
            }
        }
    }
}

.showSwap {
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .select-swap-convert {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-bottom: 25px;
        align-items: center;
        user-select: none;

        >div.input-arrow-icon {
            margin-inline-start: -10px;
            margin-inline-end: -10px;
            margin-top: 0px;
            margin-bottom: 0px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            background-color: var(--background-paper);
            background-image: linear-gradient(var(--action-selected), var(--action-selected));
            height: 30px;
            width: 30px;
            border-radius: 50%;
            border: 2px solid var(--action-hover);
            transition: border-color 0.1s ease-in-out;
            z-index: 1;

            >svg {
                fill: var(--text-primary);
                height: 20px;
                width: 20px;
            }
        }

        >div:not(.input-arrow-icon) {
            display: flex;
            flex-direction: column;
            background: var(--action-hover);
            border: 2px solid var(--action-hover);
            border-radius: 10px;
            flex: 1;
            max-width: 50%;
            height: 100%;
            padding: 10px 20px;
            cursor: pointer;
            user-select: none;

            &.active {
                border-color: var(--primary-main);
            }

            &:hover {
                background: var(--action-focus);
            }

            >span {
                font-size: 16px;
            }

            >div {
                display: flex;
                flex-direction: row;
                align-items: center;
                overflow: hidden;

                >div:first-child {
                    background-color: var(--action-hover);
                    background-size: 50% !important;
                    border-radius: 50%;
                    min-width: 40px !important;
                    min-height: 40px !important;
                }

                >div:last-child {
                    position: relative;

                    >h6 {
                        margin: 0px;
                        font-size: 18px;
                        line-height: 20px;
                    }

                    >div {
                        margin: 0px;
                        font-size: 10px;
                        line-height: 12px;
                        opacity: .6;
                        white-space: nowrap;
                        max-width: 100%;
                    }
                }
            }
        }

        @media (max-width: 600px) {
            flex-direction: column;
            border: solid 1px var(---divider);
            background-color: rgba(0, 0, 0, .01);
            border-radius: 10px;
            padding: 5px;

            >div.input-arrow-icon {
                margin-top: -10px;
                margin-bottom: -10px;

                >svg {
                    transform: rotate(90deg);
                }
            }

            >div:not(.input-arrow-icon) {
                width: 100%;
                max-width: 100% !important;
                flex: 1;
            }
        }
    }

    .input-swap-convert {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 15px;

        .input-base {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            background-color: var(--action-hover);
            border: 2px solid var(--action-hover);
            border-radius: 10px;
            width: 100%;

            &:first-child {
                margin-bottom: 20px;
            }

            >.input-symbol {
                margin: 10px 15px 5px 10px;
                height: 60px;
                width: 60px;
            }

            >.input-base-by {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 5px 0px 0px;

                > :global(.MuiInputBase-root) {
                    flex: 1;
                    padding-right: 20px;

                    input {
                        margin: 0px;
                        font-size: 25px;
                    }

                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }

                    input[type=number] {
                        -moz-appearance: textfield;
                    }
                }
            }

            >p.balance {
                font-style: italic;
                opacity: .8;
                width: 100%;
                padding: 0px 15px 4px;
            }
        }
    }

    >.description {
        margin: 0px;
        width: 100%;
        text-align: right;
        opacity: .8;

        >* {
            font-size: 14px;
        }
    }
}

.popper-component {
    z-index: 99999999;

    .popper-paper-component {
        border-radius: 10px;
        padding: 3px 4px;
        box-shadow: 0 6px 10px 0 rgba(0, 0, 0, .14), 0 1px 18px 0 rgba(0, 0, 0, .12), 0 3px 5px -1px rgba(0, 0, 0, .2);
    }
}