.Settings-Container{
    color: var(--text-primary);
    display: flex;
    height: 100%;
}

.Settings-Container > div{
    padding: 40px 0px;
    height: 100%;
}

.Settings-Container > div:first-child{
    width: 200px;
    padding-right: 10px;
    overflow-y: auto;
    overflow-x: hidden;
    transition: all .3s ease-in-out 0ms;
}

@media only screen and (max-width: 700px){
    .Settings-Container > div:first-child{
        width: 0px;
        opacity: 0;
    }
}

.Settings-Container > div:first-child > div{
    padding: 8px 0px 8px 10px;
    display: flex;
    align-items: center;
    border-radius: 0px 20px 20px 0px;
    border-left: 4px solid transparent;
}

.Settings-Container > div:first-child > div.active{
    border-left-color: var(--Settings-menu-item);
    background-color: transparent !important;
    cursor: auto !important;
}

.Settings-Container > div:first-child > div.active > *{
    opacity: .8;
}

.Settings-Container > div:first-child > div.title{
    margin-bottom: 25px;
}

.Settings-Container > div:first-child > div:not(.title){
    cursor: pointer;
}

.Settings-Container > div:first-child > div:hover:not(.title){
    background-color: rgba(0, 0, 0, .2);
}

.Settings-Container > div:first-child > div > svg{
    font-size: 20px;
}

.Settings-Container > div:first-child > div > div{
    flex: 1;
    margin: 0px;
    line-height: 1;
    margin-left: 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.Settings-Container > div:last-child{
    flex: 1;
    overflow-y: auto;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

.Settings-Container > div:last-child .MuiDivider-root{
    margin: 40px 0px 20px 0px;
}

.Settings-Container > div:last-child .Settings-List{
    margin-top: 10px;
}

.Settings-Container > div:last-child .Settings-List div.MuiGrid-root.MuiGrid-item{
    margin-top: 0px !important;
    padding-top: 0px !important;
}

.Settings-Container > div:last-child .Settings-List div.Settings-List-item{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px 15px;
    border-radius: 10px;
    margin: 5px 0px;
    min-height: 50px;
}

.Settings-Container > div:last-child .Settings-List div.Settings-List-item.disabled{
    opacity: .5;
}

.Settings-Container > div:last-child .Settings-List div.Settings-List-item:hover{
    background-color: rgba(0, 0, 0, .1);
}

.Settings-Container > div:last-child .Settings-List div.Settings-List-item > *{
    margin: 0px;
}

.Settings-Container > div:last-child .Settings-List div.Settings-List-item > svg:first-child{
    margin-right: 20px;
    font-size: 24px;
    line-height: 1;
}

.Settings-Container > div:last-child .Settings-List div.Settings-List-item > div.MuiTypography-root{
    flex: 1;
    font-size: 16px;
    margin-right: 5px;
}

.Settings-Container > div:last-child .Settings-List div.Settings-List-item > p.MuiTypography-root{
    font-size: 16px;
    opacity: .9;
    font-weight: bold;
}

.Settings-Container > div:last-child .Settings-List div.Settings-List-item .MuiInputBase-root > .MuiInputBase-input{
    font-size: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
}