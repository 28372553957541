.container {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.scrollable::-webkit-scrollbar {
    display: none;
}

.horizontalSpacer {
    position: relative;
    height: 50%;
    width: 100%;
}

.rowContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 50%;
    padding-right: 50%;
    box-sizing: content-box;
}

.row {
    display: flex;
    flex-direction: row;
}

.bubbleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bubble {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    /* overflow: hidden; */
}

.bubble > * {
    width: 100% !important;
    height: 100%;
}

.guideContainer {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
}

.guide {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border: 2px solid rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.12);
}

.scrollable {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: scroll !important;
    scroll-padding: 100px 0 0 100px;
    -ms-overflow-style: none;
    scrollbar-width: none;
}