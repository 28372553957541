 .mover-griD-from-scren-four {
    margin-top: 40px;
    margin-left: 7px;
    margin-right: 7px;
}
.ajustEI-todo{
    background-color: var(--Tela4-background);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 1px;
    padding: 10px;
}
.ajUstEi-todo{
    background-color: var(--Tela4-background);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 1px;
    padding: 10px; 
}
.AvatetionsS{
    margin-left: 7px;
}
.icone-from-imagem{
    margin-top:3px;
    margin-left: 0px;
}
.avatetions{
    margin-right:3px;
}
.aVatar-junio{
 margin-top:6px;
 margin-left: 1px;
}
.Primiero-MeTaMasK{
    margin-left:0px;
}
.casinhA-los-numeros{
    display: flex;
    justify-content:center;
    margin-right:30px;
    font-size: 10px;
    color:var(--text-primary);
}
.casinha-dos-numeros{
    font-size: 10px;
    color:var(--text-primary);
    margin-right:20px;
}
.froM-baby-doge{
   color:var(--text-primary);
   font-size:10px;
}
.MeTaMasK button{
    background-color:transparent !important;
}
.MeTa-Da-MasK:hover{
    background-color:rgba(255, 255, 255, 0.2)!important;
}
.MeTa-Da-MasK button{
    background-color:transparent !important;
}
.my-numEros{
    display: flex;
    justify-content:center;
    margin-right:45px;
    font-size: 10px;
    color:var(--text-primary);
}
.fRom-bAby-doge{
    color:var(--text-primary);
    font-size:10px;
}
.metA-mAks-penultimo{
    margin-right:10px;
}
.metA-mAks-penultimo button{
    background-color:transparent !important;
}
.casInha-dos-numeros-ultimo{
    display: flex;
    justify-content:center;
    margin-right:30px;
    font-size: 10px;
    color:var(--text-primary);
}
.MuiTypography-root-325345{
    color: var(--text-primary);
}
.MuiTableContainer-root-25345{
background-color:#5F5F63!important;
height:220 px !important;

}
