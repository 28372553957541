.container {
    width: 100%;
    height: 100%;
    display: flex;
}

.calendar-content {
    width: 80%;
}

.left-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
    height: 100%;
    color: grey;
    cursor: pointer;
}

.right-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
    height: 100%;
    color: grey;
    cursor: pointer;
}

.month-title {
    width: 100%;
    text-align: center;
    color: rgb(170, 170, 170);
    padding-block: 20px;
}