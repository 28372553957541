.btn {
    display: flex;
    margin-top: 0px;
    --border-botton-width: 4px;
    --border-radius: 12px;

    * {
        color: rgb(var(--color-primary)) !important;
    }

    >button {
        background-color: rgb(var(--color-light));
        width: 100%;
        border: solid transparent;
        border-radius: var(--border-radius);
        border-width: 2px 2px var(--border-botton-width);
        height: 40px;
        padding: 0px 16px 0px 10px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        letter-spacing: 1px;
        text-decoration: none;
        text-transform: uppercase;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        white-space: nowrap;
        cursor: pointer;
        outline: none;
        touch-action: manipulation;

        &::before {
            content: "";
            background-color: rgb(255, 255, 255);
            border-radius: var(--border-radius);
            position: absolute;
            right: -2px;
            top: -2px;
            bottom: -2px;
            left: -2px;
        }

        &:hover {
            opacity: .9;
        }

        &:active {
            border-bottom-width: 2px;
        }
    }

    &.disabled {
        opacity: .8 !important;

        >button {
            cursor: auto;
            opacity: .8 !important;

            &:hover {
                opacity: .8 !important;
            }

            &:active {
                border-bottom-width: var(--border-botton-width);
            }
        }
    }

    @media screen and (max-width: 800px) {
        margin-top: 25px;
        --border-botton-width: 6px;
        --border-radius: 16px;

        >button {
            height: 60px;
            font-size: 16px;
            padding: 0px 25px 0px 15px;
        }
    }
}