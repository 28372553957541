:global(.theme-dark) {
    .root {
        --color: var(--color-dark);

        .navigationBar,
        .header {
            --text-primary: rgb(var(--text-color-dark));
            --text-primary-rgb: var(--text-color-dark);
            --action-disabled: rgba(var(--text-color-dark), .4);
        }
    }
}

.root {
    position: relative;
    --color: var(--color-light);

    .navigationBar,
    .header {
        --text-primary: rgb(var(--text-color-light));
        --text-primary-rgb: var(--text-color-light);
        --action-disabled: rgba(var(--text-color-light), .4);
    }

    .navigationBar {
        position: sticky;
        top: 0px;
        left: 0px;
        right: 0px;
        width: auto;
        background-color: rgba(var(--color), 1);
        padding: 5px;
        color: var(--text-primary) !important;
        display: flex;
        flex-direction: row;
        align-items: center;
        z-index: 4;

        >* {
            color: var(--text-primary) !important;
        }

        :global(.Mui-disabled) {
            opacity: .3;
        }

        :global(.MuiCircularProgress-root) {
            margin: 0px 15px;
        }

        >.title {
            flex: 1;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 18px;
            margin: 0px 15px;
        }

        &.active {
            box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .14), 0 3px 3px -2px rgba(0, 0, 0, .2), 0 1px 8px 0 rgba(0, 0, 0, .12);
        }
    }

    .header {
        color: var(--text-primary);
        background-color: rgba(var(--color), 1);
        padding: 15px;
        z-index: 2;

        >* {
            color: var(--text-primary) !important;
        }
    }

    .content {
        min-height: 300px;
        padding: 15px;
        z-index: 1;
        display: flex;
        flex-direction: column;
        align-items: center;

        &:not(.disabled)::before {
            content: "";
            position: absolute;
            z-index: 0;
            top: 0px;
            left: 0px;
            right: 0px;
            height: 100%;
            max-height: 400px;
            background-image: linear-gradient(rgba(var(--color), .9) 20%, transparent);
            transition: background-image .3s;
        }

        >* {
            z-index: 2;
        }

        :global(.MuiCircularProgress-root) {
            color: var(--text-primary);
            opacity: .3;
            margin: 40px auto;
        }
    }
}