.root {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--Quiz-gameText);
    margin: 0 auto;
    width: 100%;
    height: 100%;
    max-height: 100%;
    padding: 20px 0px;

    * {
        font-family: "din-round", sans-serif !important;
    }

    >:global(.MuiCircularProgress-root) {
        color: var(--text-primary);
        opacity: .5 !important;
    }

    .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 95%;
        max-width: 800px;
        height: 100%;
        max-height: 100%;
        padding-inline: 12px;
        overflow: hidden;

        .bar-tools {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 100%;
            margin: 0 auto;
            gap: 24px;

            >* {
                margin-top: 0px;
                margin-bottom: 0px;
            }

            .arrowback-icon {
                color: var(--text-primary);
                cursor: pointer;

                &:hover {
                    opacity: .6;
                }
            }

            .progress-bar-container {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                height: 20px;
                margin: 0 auto;
                border-radius: 16px;
                background-color: rgba(0, 0, 0, .2);
                padding: 5px;

                .progress-bar {
                    min-width: 10px;
                    max-width: 100%;
                    flex: 1;
                    border-radius: 16px;
                    background-color: rgb(249, 76, 0);
                    background-image: linear-gradient(90deg, transparent 0%, rgba(255, 255, 255, .4) 50%, transparent 100%);
                    transition: width 0.5s ease;
                }
            }

            .btn-tool {
                --color: var(--text-primary);
                display: flex;
                align-items: center;
                font-weight: 700;
                justify-content: space-between;
                font-size: 23px;
                gap: 10px;
                cursor: pointer;

                &:hover {
                    opacity: .6;
                }

                &.help-question {
                    --color: rgb(249, 76, 0);
                }

                &.disabled {
                    --color: rgba(0, 0, 0, .4);
                    cursor: auto;
                    opacity: .6;
                }

                span {
                    color: var(--color);
                }

                .favorite-border-icon {
                    color: var(--color);
                }
            }
        }

        .main-div {
            flex: 1;
            display: flex;
            flex-direction: column;
            overflow: hidden;

            &::before,
            &::after {
                content: "";
                position: absolute;
                left: 0px;
                right: 0px;
                height: 30px;
                z-index: 4;
            }

            &::before {
                top: 0px;
                background-image: linear-gradient(180deg, var(--WindowComponent-content-background) 0%, transparent 100%);
            }

            &::after {
                bottom: 0px;
                background-image: linear-gradient(0deg, var(--WindowComponent-content-background) 0%, transparent 100%);
            }

            >div {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
                overflow-y: auto;
                padding: 24px 15px;
                z-index: 1;
                line-height: 1.5;

                &.article {

                    h1,
                    h2,
                    h3,
                    h4,
                    h5,
                    h6,
                    p {
                        margin: 4px 0px;
                    }

                    ul,
                    ol {
                        list-style: inherit !important;

                        li {
                            list-style: inherit !important;
                            margin: 8px 0px;
                        }
                    }
                }

                &.countdown {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;

                    >.countdown-number {
                        width: auto;
                        height: auto;
                        position: absolute;
                        left: 50%;
                        top: 45%;
                        transform: translate(-50%, -50%);
                        color: var(--text-primary);
                        font-size: 8em;
                        text-align: center;
                        font-weight: bold;
                        font-style: italic;
                        opacity: 1;
                        transition: opacity 0.4s ease;
                        perspective: 1000;
                        backface-visibility: hidden;

                        &.puffer {
                            opacity: 0;
                            font-size: 20em;
                            transition: font-size 0.4s ease-out, opacity 0.4s ease-out;
                        }
                    }

                    >:global(.MuiTypography-body1) {
                        position: absolute;
                        left: 50%;
                        bottom: 25px;
                        transform: translateX(-50%);
                        opacity: .6;
                        color: var(--text-primary);
                        width: 90%;
                        max-width: 400px;
                        text-align: center;
                    }
                }

                .fake-space {
                    width: 100%;
                    height: 10px;
                    background: transparent;
                }

                .main-header {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 100%;
                    margin: 0 auto;

                    .question-div {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-between;
                        border: 2px solid rgba(0, 0, 0, .2);
                        background-color: rgba(0, 0, 0, .1);
                        width: 100%;
                        border-radius: 12px;
                        margin: 0 auto;
                        margin-bottom: 20px;
                        text-align: center;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        padding: 0px 15px 15px;

                        p {
                            color: #2b2b2b;
                            font-weight: 600;
                        }

                        .pergunta {
                            font-size: 14px;
                            background-color: rgba(0, 0, 0, .2);
                            margin-top: 0px;
                            padding: 5px 25px;
                            border-radius: 0px 0px 10px 10px;
                        }

                        .question-text {
                            color: var(--Quiz-gameText);
                            font-weight: 700;
                            font-size: 20px;
                            text-align: center;
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }
                }

                .questions-buttons {
                    display: flex;
                    justify-content: center;
                    flex-direction: row;
                    gap: 15px;
                    width: 100%;
                    margin: 20px auto;
                    flex-wrap: wrap;

                    >.button {
                        --color: var(--text-primary);
                        display: flex;
                        justify-content: space-between;
                        padding: 10px;
                        align-items: flex-start;
                        height: auto !important;
                        flex: 1 1 48%;
                        margin: 0px;
                        gap: 24px;
                        border-radius: 12px;
                        background-color: transparent;
                        border: 2px solid rgba(0, 0, 0, .2);
                        color: var(--text-primary);
                        overflow: hidden;

                        &:hover {
                            border: 2px solid rgba(0, 0, 0, .4);
                            background-color: rgba(0, 0, 0, .1);
                        }

                        >p {
                            font-weight: 600;
                            margin: 0px;
                        }

                        .button-alternative {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border: 2px solid rgba(0, 0, 0, .2);
                            border-radius: 6px;
                            color: var(--color);
                            font-weight: 700;
                            z-index: 2;
                            width: 30px;
                            height: 30px;
                            font-size: 14px;
                        }

                        .button-option {
                            flex: 1;
                            font-size: 16px;
                            text-align: left;
                            text-transform: none;

                            * {
                                margin: 0px;
                            }
                        }

                        &.selected {
                            --color: var(--primary-main);
                            border-color: var(--primary-main);

                            &::before {
                                content: "";
                                position: absolute;
                                top: 0;
                                left: 0;
                                width: 100%;
                                height: 100%;
                                background-color: var(--primary-main);
                                opacity: .1;
                            }

                            .button-alternative {
                                border-color: var(--primary-main);
                                background-color: rgba(0, 0, 0, .2);
                            }
                        }
                    }
                }
            }
        }

        .sections {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            width: 100%;
            gap: 20px;
            padding: 0px;

            .progress-with-label {
                position: relative;
                display: inline-flex;
                background-color: rgba(0, 0, 0, .1);
                border-radius: 50%;
                width: 44px;
                height: 44px;

                >:global(.MuiCircularProgress-root) {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    translate: -50% -50%;
                    width: 40px;
                    height: 40px;
                }

                >div {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    top: 0px;
                    left: 0px;
                    right: 0px;
                    bottom: 0px;
                    width: auto;
                    height: auto;
                    color: var(--text-primary);
                }
            }

            >button {
                border-radius: 12px;
                border: none;
                background-color: rgb(249, 76, 0);
                background-image: linear-gradient(90deg, transparent 0%, rgba(255, 255, 255, .2) 50%, transparent 100%);
                border: 2px solid rgba(0, 0, 0, .2);
                color: #ffffff;
                text-transform: uppercase;
                font-weight: 600;
                height: 44px;
                text-align: center;
                width: 40%;
                font-size: 20px;

                &:disabled {
                    opacity: .6;
                    filter: grayscale(100%);
                }

                p {
                    width: 100%;
                    text-align: center;
                }
            }
        }
    }

    @media screen and (max-width: 700px) {
        .main-div {
            .questions-buttons {
                >.button {
                    flex: 1 1 100% !important;
                }
            }
        }
    }
}