.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 100px;
}

.chartMain {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    div.chart-content {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 5px;

        div.chart-view {
            flex: 1;
            width: 100%;
            height: 100%;
            overflow: hidden;
            position: relative;
            border-radius: 10px;
            background-color: transparent;
            border: 0px solid transparent;
            box-shadow: none;

            &.background {
                background-color: var(--Chart-chartView-background);
                border: 1px solid var(--Chart-chartView-border);
                box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
            }
        }

        &.chart-area {
            >div:first-child {
                flex: 1;
                display: flex;
                flex-direction: row;

                div.vAxis {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: flex-end;
                    padding: 0px 10px;
                    color: var(--text-primary);
                    font-size: 11px;
                }

                div.chart-view {

                    >svg {
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        width: 100%;
                        height: 100%;
                        z-index: 0;

                        path {
                            stroke-width: 3px;

                            &.zeroLine {
                                stroke: var(--Chart-zeroLine-stroke);
                                stroke-width: 1px;
                            }

                            &.indexLine {
                                stroke: var(--Chart-zeroLine-stroke);
                                stroke-width: 1px;
                                display: none;
                                opacity: .4;
                            }
                        }

                        g.indexes>g {
                            display: none;
                        }
                    }

                    >.popup {
                        position: absolute;
                        top: 0px;
                        left: 0px;
                        background: var(--Chart-popup-background);
                        border: 1px solid var(--Chart-popup-border);
                        border-radius: 5px;
                        color: var(--text-primary);
                        display: flex;
                        flex-direction: column;
                        padding: 5px 10px;
                        z-index: 999;
                        transform: translate(-100%, -50%);
                        transition: all .3s cubic-bezier(0.46, 0.03, 0.52, 0.96);

                        &::before {
                            content: "";
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            right: 0px;
                            bottom: 0px;
                            width: auto;
                            height: auto;
                            backdrop-filter: blur(5px);
                            z-index: 0;
                            border-radius: 6px;
                        }

                        * {
                            white-space: nowrap;
                            z-index: 2;
                        }

                        p,
                        span {
                            margin: 0px;
                            padding: 0px;
                        }

                        .popup-title {
                            font-size: 14px;
                        }

                        .popup-item {
                            font-size: 12px;
                            display: flex;
                            flex-direction: row;
                            justify-self: space-between;
                            align-items: center;
                            padding: 2px 0px;

                            .popup-item-line {
                                width: 20px;
                                height: 4px;
                                border-radius: 2px;
                                background-color: rgba(0, 0, 0, .7);
                            }

                            span {
                                padding-left: 5px;
                            }
                        }

                        .popup-subtitle {
                            font-size: 11px;
                            opacity: .8;
                            font-weight: bold;
                            text-align: center;
                        }
                    }
                }
            }

            >div:last-child {
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;

                >.hAxis {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    margin: 10px 0px;
                    color: var(--text-primary);
                    font-size: 11px;

                    >* {
                        margin: 0px 5px;
                    }
                }
            }
        }

        &.chart-pie {
            flex-direction: row;
            overflow: hidden;

            >div.chart-view {

                >svg {
                    position: absolute;
                    top: 0px;
                    left: 0px;
                    width: 100%;
                    height: 100%;
                    z-index: 0;
                    transition: opacity .3s cubic-bezier(0.46, 0.03, 0.52, 0.96);

                    >g {
                        transition: all .2s cubic-bezier(0.46, 0.03, 0.52, 0.96);

                        >path.marker {
                            cursor: pointer;
                        }

                        >path.indicator {
                            stroke: var(--text-primary);
                            stroke-width: 2px;
                            opacity: .7;
                            fill: transparent;
                        }

                        >text.indicatorLabel {
                            fill: var(--text-primary);
                            font-size: 12px;
                            letter-spacing: 2px;
                            font-weight: bold;
                        }
                    }
                }
            }

            >div.indexes {
                color: var(--text-primary);
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                padding: 5px 0px;
                flex-wrap: nowrap;
                height: auto;
                overflow-y: auto;
                overflow-x: hidden;
                justify-content: start;

                &.align-center {
                    justify-content: center;
                }

                &.align-end {
                    justify-content: end;
                }

                >.indexes-item {
                    font-size: 14px;
                    display: flex;
                    flex-direction: row;
                    justify-self: space-between;
                    align-items: center;
                    padding: 2px 10px;
                    font-weight: bold;
                    cursor: pointer;
                    white-space: nowrap;
                    transition: all .2s cubic-bezier(0.46, 0.03, 0.52, 0.96);

                    .indexes-item-circle {
                        width: 15px;
                        height: 15px;
                        border-radius: 50%;
                        background-color: rgba(0, 0, 0, .7);
                    }

                    span {
                        opacity: 1;
                        padding-left: 5px;
                    }
                }
            }

            &.chart-pie-horizontal {
                flex-direction: column;

                >div.indexes {
                    flex-direction: row;
                    flex-wrap: wrap;
                    overflow-y: hidden;
                    overflow-x: hidden;
                }
            }
        }

        &.chart-comparative {
            display: flex;
            flex-direction: column;

            .content {
                display: flex;
                flex-direction: column;
                flex: 1;

                >div {
                    display: flex;
                    flex-direction: row;

                    .y-axis {
                        display: flex;
                        flex-direction: column-reverse;
                        justify-content: space-between;
                        align-items: flex-end;
                        padding-right: 10px;
                        padding-top: 15px;

                        >div {
                            color: var(--text-primary);
                            opacity: .8;
                            font-size: 10px;
                            white-space: nowrap;
                        }
                    }

                    &:last-child>.y-axis {
                        flex-direction: column;
                        padding-top: 0px;
                        padding-bottom: 15px;
                    }

                    .graph {
                        flex: 1;
                        display: flex;
                        flex-direction: row-reverse;
                        justify-content: space-between;
                        background-color: var(--Chart-chartView-background);
                        border: 2px solid var(--Chart-chartView-border);
                        border-radius: 10px 10px 0px 0px;
                        border-bottom-width: 0px;
                        padding: 10px 15px 0px 15px;
                        overflow: hidden;

                        >div {
                            display: flex;
                            flex-direction: row;
                            width: 50px;
                            min-width: 50px;
                            justify-content: center;
                            align-items: end;
                            padding: 2px 0px;

                            >div {
                                width: 15px;
                                min-height: 15px;
                                border-radius: 10px;
                                background: var(--Chart-color-primary);
                            }
                        }
                    }

                    &:last-child>.graph {
                        border-radius: 0px 0px 10px 10px;
                        border-top-width: 1px;
                        border-bottom-width: 2px;
                        padding: 0px 15px 10px 15px;

                        >div {
                            align-items: start;

                            >div {
                                background: var(--Chart-color-secondary);
                            }
                        }
                    }
                }
            }

            .x-axis {
                display: flex;
                flex-direction: row-reverse;
                justify-content: space-between;
                padding: 5px 15px 10px 15px;

                >div {
                    color: var(--text-primary);
                    opacity: .8;
                    font-size: 10px;
                    padding: 0px 10px;
                    width: 50px;
                    max-width: 50px;
                }
            }
        }

        &.chart-radar {
            overflow: hidden;

            >svg {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0px;
                left: 0px;

                .grid {

                    >path,
                    >line {
                        stroke: var(--text-primary);
                        opacity: .1;
                        stroke-width: 2px;
                        fill: none;
                    }

                    >text {
                        font-size: 12px;
                        font-weight: bold;
                        fill: var(--text-primary);
                        opacity: .5;
                    }
                }

                .marker {
                    >path {
                        stroke-width: 2px;
                    }
                }
            }
        }
    }
}