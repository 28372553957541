.main {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    opacity: 0;
    background-color: transparent;
    z-index: 9999999;
    overflow-y: auto;
    display: block;
    animation-name: hidden_view;
    animation-fill-mode: forwards;
    animation-duration: .2s;
    animation-timing-function: linear;
    animation-direction: alternate-reverse;

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 90%;
        max-width: 500px;
        min-height: 400px;
        margin: 50px auto;
        user-select: none;

        .button-close {
            position: absolute;
            top: 8px;
            right: 8px;
            border: 2px solid rgb(var(--button-color));
            background-color: rgb(var(--color));
            padding: 0;
            cursor: pointer;
            display: block;
            height: 40px;
            width: 40px;
            overflow: hidden;
            z-index: 999999;
            border-radius: 10px 10px 0px 10px;

            &::before {
                content: "";
                display: block;
                height: 100%;
                position: absolute;
                top: 0;
                width: 120%;
                left: -10%;
                transform: translateX(-100%) skew(-10deg);
                transition: transform .2s ease-in-out;
                background-color: rgba(var(--button-color), .9);
            }

            &::after {
                content: "";
                position: absolute;
                display: block;
                bottom: 0px;
                right: 0px;
                height: 5px;
                width: 5px;
                background-color: rgb(var(--button-color));
            }

            .icon {
                display: inline-block;
                pointer-events: none;
                color: rgb(var(--button-color));
                height: 100%;
                position: relative;
                width: 100%;
                transform: scale(1) rotate(0deg);
                transform-origin: center;
                transition: transform .2s ease-in-out, color .2s ease-in-out;

                svg {
                    display: block;
                    height: 100%;
                    width: 100%;
                    stroke: currentColor;
                    stroke-width: 4px;
                }
            }

            &:hover {
                &::before {
                    transform: translateX(0) skew(-10deg);
                }

                .icon {
                    color: rgb(var(--color));
                    transform: scale(1.2) rotate(-90deg);
                }
            }
        }

        .view-image {
            z-index: 1;
            position: relative;
            border-radius: 14px 14px 0px 0px;
            width: 100%;
            background-color: rgb(var(--color));
            padding-top: 100%;

            * {
                z-index: 0;
            }

            .ribbon {
                width: 150px;
                height: 150px;
                overflow: hidden;
                position: absolute;
                top: -10px;
                left: -10px;
                z-index: 1;

                &::before,
                &::after {
                    position: absolute;
                    z-index: -1;
                    content: '';
                    display: block;
                    border: 5px solid #2980b9;
                    border-top-color: transparent;
                    border-left-color: transparent;
                }

                &::before {
                    top: 0;
                    right: 0;
                }

                &::after {
                    bottom: 0;
                    left: 0;
                }

                span {
                    position: absolute;
                    display: block;
                    width: 225px;
                    padding: 15px 0;
                    background-color: #3498db;
                    box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
                    color: #fff;
                    font: 700 18px/1 'Lato', sans-serif;
                    text-shadow: 0 1px 1px rgba(0, 0, 0, .2);
                    text-transform: uppercase;
                    text-align: center;
                    right: -25px;
                    top: 30px;
                    transform: rotate(-45deg);
                }
            }

            .image {
                position: absolute;
                top: 15px;
                left: 15px;
                right: 15px;
                bottom: 15px;
                width: auto;
                height: auto;
                background-position: center;
                background-repeat: no-repeat;
                background-size: cover;
                border-radius: 10px;

                .topLeft,
                .bottomRight,
                .bottomLeft,
                .topRight {
                    background-color: rgba(var(--color), .5);
                    position: absolute;

                    &::before,
                    &::after {
                        content: "";
                        position: absolute;
                        background-color: rgba(var(--color), .5);
                    }
                }

                .topLeft {
                    height: 80px;
                    top: 40px;
                    width: 40px;
                }

                .topRight {
                    height: 40px;
                    top: 0px;
                    right: 0px;
                    width: 40px;
                    background-color: rgb(var(--color));

                    &::before {
                        top: 0px;
                        right: 100%;
                        display: block;
                        height: 40px;
                        width: 40px;
                    }
                }

                .bottomLeft {
                    bottom: 0;
                    height: 40px;
                    position: absolute;
                    width: 40px;
                    background-color: rgb(var(--color));

                    &::before {
                        bottom: 100%;
                        right: 0px;
                        display: block;
                        height: 40px;
                        width: 40px;
                    }

                    &::after {
                        bottom: 0px;
                        left: 100%;
                        display: block;
                        height: 40px;
                        width: 40px;
                    }
                }

                .bottomRight {
                    bottom: 0px;
                    right: 0px;
                    display: block;
                    height: 80px;
                    width: 80px;

                    &::before {
                        bottom: 0px;
                        right: 40px;
                        display: block;
                        height: 40px;
                        width: 80px;
                    }

                    &::after {
                        bottom: 0px;
                        right: 0px;
                        display: block;
                        height: 40px;
                        width: 40px;
                        background-color: rgb(var(--color));
                    }
                }

                .marking {
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    right: 10px;
                    bottom: 10px;
                    border: 1px solid rgba(255, 255, 255, .3);
                    border-radius: 10px;

                    .top,
                    .bottom {
                        position: absolute;
                        left: 0px;
                        right: 0px;


                        &::before,
                        &::after {
                            content: "";
                            position: absolute;
                            width: 40px;
                            height: 40px;
                            border-color: rgba(255, 255, 255, .4);
                            border-style: solid;
                            border-top-width: 0px;
                            border-left-width: 0px;
                            border-right-width: 0px;
                            border-bottom-width: 0px;
                            border-top-left-radius: 0px;
                            border-top-right-radius: 0px;
                            border-bottom-left-radius: 0px;
                            border-bottom-right-radius: 0px;
                        }
                    }

                    .top {
                        top: 0px;

                        &::before {
                            top: 0px;
                            left: 0px;
                            border-top-width: 3px;
                            border-left-width: 3px;
                            border-top-left-radius: 10px;
                        }

                        &::after {
                            top: 0px;
                            right: 0px;
                            border-top-width: 3px;
                            border-right-width: 3px;
                            border-top-right-radius: 10px;
                        }
                    }

                    .bottom {
                        bottom: 0px;

                        &::before {
                            bottom: 0px;
                            left: 0px;
                            border-bottom-width: 3px;
                            border-left-width: 3px;
                            border-bottom-left-radius: 10px;
                        }

                        &::after {
                            bottom: 0px;
                            right: 0px;
                            border-bottom-width: 3px;
                            border-right-width: 3px;
                            border-bottom-right-radius: 10px;
                        }
                    }
                }
            }
        }

        .view-info {
            background-image: linear-gradient(rgb(var(--color)), rgba(var(--color), 0));
            width: 100%;
            padding: 15px 15px 100px 15px;

            .code {
                text-align: center;
                font: 16px Inconsolata, monospace;
                letter-spacing: 2px;
                color: rgb(var(--text-color));
            }

            .title {
                text-transform: uppercase;
                padding: 5px 15px;
                margin: 10px 10px 0px 10px;
                border: 1px solid rgba(255, 255, 255, .3);
                background: radial-gradient(circle, rgba(255, 255, 255, .8) 41%, rgba(255, 255, 255, .3) 100%);
                border-radius: 10px 10px 0px 0px;
                font-size: 18px;
                text-align: center;
                color: #263238;
                font-weight: bold;
                letter-spacing: 2px;
                min-height: 30px;
            }

            .description {
                padding: 10px 15px;
                margin: 0px 10px 25px 10px;
                border: 1px solid rgba(255, 255, 255, .3);
                border-top-width: 0px;
                border-radius: 0px 0px 10px 10px;

                :global(.MuiTypography-body1) {
                    font-style: italic;
                }
            }

            .user-info {
                display: flex;
                align-items: center;
                border: 2px dashed rgba(var(--text-color), .3);
                background-color: rgba(var(--text-color), .05);
                border-radius: 50px;
                padding: 5px;
                margin: 0px 10px 25px 10px;
                overflow: hidden;

                .picture {
                    width: 40px;
                    min-width: 40px;
                    height: 40px;
                    min-height: 40px;
                    background-color: rgba(0, 0, 0, .2);
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    border-radius: 50%;
                }

                .label {
                    padding: 0px 15px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    flex: 1;
                    flex: 1;
                    width: auto;
                    overflow: hidden;

                    * {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        line-height: 1;
                        opacity: .8;
                        max-width: 100%;
                    }

                    :global(.MuiTypography-body1) {
                        line-height: 1.2;
                        opacity: 1;
                    }
                }
            }
        }
    }

    &.show {
        animation-name: show_view;
        animation-direction: alternate;

        @keyframes show_view {
            to {
                background-color: rgba(0, 0, 0, .7);
                opacity: 1;
            }
        }
    }

    @keyframes hidden_view {
        to {
            background-color: rgba(0, 0, 0, .7);
            opacity: 0;
        }
    }
}