:global(body.theme-dark) {
    .voucher {
        &.found {
            --border-color: 255, 193, 7;
            --text-primary: rgba(var(--border-color), .8);
        }
    }
}

.voucher {
    --border-color: 0, 0, 0;

    &.found {
        --border-color: 255, 179, 0;
        --text-primary: rgba(255, 109, 0, 1);
    }

    border: solid 2px rgba(var(--border-color), .2);
    background-color: rgba(var(--border-color), .1);
    margin: 20px auto;
    border-radius: 10px;
    width: 95%;
    max-width: 400px;

    >* {
        padding: 15px;
        color: var(--text-primary);
    }

    >h5 {
        border-bottom: solid 2px rgba(var(--border-color), .2);
    }

    >.main {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 15px;
        min-height: 100px;
    }
}

.ProfilePage {
    margin-bottom: 150px;

    >.ProfilePage-picture {
        margin: 30px 0px;
        width: 100%;
    }

    >.ProfilePage-container {
        padding: 15px 20px;

        .ProfilePage-grid-item {
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
        }
    }
}

.temporary-voucher {
    display: flex;
    flex-direction: column;
    gap: 14px;
    align-items: center;
    justify-content: center;
}

.rescued-voucher {
    text-align: center;
    color: white;
    font-weight: 700;
}

.sponsorDiv {
    padding: 20px 10px;
    text-align: center;
}

.sponsorButton {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 18px auto;
}

.SvgReferralLink {
    width: 100%;
    height: 100%;
    padding: 15px;

    >svg {
        width: 100%;
        height: 100%;
    }
}

.container-box {
    flex-direction: row;
    margin: 15px 0px;

    &.reverse {
        flex-direction: row-reverse;
    }

    @media (max-width: 600px) {
        &.reverse {
            flex-direction: row;
        }
    }

    .image {
        width: 100%;
        height: 100%;
        min-height: 300px;
        padding: 15px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    *:has(.content) {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .content {
        background-color: var(--box-background);
        margin: 0px;
        border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, .2);
        min-height: 100px;
        width: 100%;
        margin: 25px 0px;

        >.body {
            padding: 25px 20px;

            >* {
                text-align: justify;
            }

            >.definitions {
                border: solid 1px var(--action-focus);
                border-radius: 10px;
                padding: 10px 0px;

                :global(.MuiDivider-root) {
                    color: var(--text-primary);
                    margin: 25px 0px 0px;
                }

                >*:not(:global(.MuiDivider-root)) {
                    margin: 25px 20px 5px;

                    &.definition-label-edit {
                        display: flex;
                        flex-direction: row;
                        flex-wrap: nowrap;
                        align-items: center;
                        padding: 10px 15px;
                        background-color: rgba(0, 0, 0, .1);
                        border: solid 1px var(--action-focus);
                        border-radius: 10px;

                        >*:first-child {
                            flex: 1;
                        }
                    }
                }
            }

            >.actions {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                margin: 30px 0px 10px;
                width: 100%;

                >.action {
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    margin: 10px 5px;
                    width: 80px;
                    cursor: pointer;

                    >* {
                        color: var(--text-primary);
                    }

                    &:hover {
                        >.action-logo {
                            opacity: .8;
                        }

                        >.action-label {
                            opacity: .5;
                        }
                    }

                    >.action-logo {
                        align-items: center;
                        display: flex;
                        justify-content: center;
                        height: 50px;
                        width: 50px;
                        border-radius: 50px;
                        background-color: var(--action-focus);
                        opacity: 1;
                        transition: opacity .14s ease-in-out 0ms;

                        >svg {
                            height: 55%;
                            width: 55%;
                        }
                    }

                    >.action-label {
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18px;
                        margin-top: 10px;
                        text-align: center;
                        opacity: 1;
                        transition: opacity .14s ease-in-out 0ms;
                    }
                }
            }

            >.referenceInfo-label {
                display: flex;
                align-items: center;
                border: 2px dashed var(--action-selected);
                border-radius: 50px;
                padding: 10px;
                cursor: auto;
                margin-top: 25px;

                &:hover {
                    >.span {
                        opacity: 1;
                    }
                }

                >* {
                    width: auto !important;
                    transition: all .14s ease-in-out 0ms;
                }

                >.span {
                    flex: 1;
                    padding: 0px 15px;
                    font-size: 14px;
                    opacity: 1;
                    color: var(--text-primary);
                }
            }
        }

        >.referenceInfo-associates {
            color: var(--text-primary);
            font-size: 14px;
            background-color: rgba(0, 0, 0, .2);
            padding: 15px 20px;
            border-radius: 0px 0px 5px 5px;
        }
    }
}

.tracking_content {
    display: flex;
    flex-direction: column;
    padding: 30px 15px;

    >div {
        --color: #9e9e9e;
        min-height: 20px;
        padding: 5px 15px 5px 30px;

        &.concluded {
            --color: var(--success-main);
        }

        &.inProgress {
            --color: var(--info-main);
        }

        &.canceled {
            --color: var(--error-main);
        }

        &:first-child {
            padding-top: 0px;

            .tracking_indicator {
                top: -10px;
            }
        }

        &:last-child {
            padding-bottom: 0px;

            &::before {
                display: none;
            }

            .tracking_indicator {
                top: 10px;
            }
        }

        &::before {
            content: "";
            position: absolute;
            left: -2px;
            top: 20px;
            bottom: -20px;
            background-color: var(--color);
            width: 4px;
            height: auto;
        }

        &.internal {
            --nr: 2;
            /* number of rows */
            --nc: 1;
            /* number of columns */
            --b: 5px;
            /* border length */
            height: 80px;

            &::before {
                background-color: transparent;
                background: conic-gradient(from 90deg at var(--b) var(--b), var(--color) 90deg, #0000 0) calc(-1*var(--b)) calc(-1*var(--b))/ calc(100px/var(--nc)) calc(20px/var(--nr));
            }
        }

        >.tracking_indicator {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            top: 15px;
            left: 0px;
            transform: translate(-50%, 0%);
            position: absolute;
            background-color: var(--color);
            border-radius: 50%;
            width: 40px;
            height: 40px;

            >svg {
                width: 24px;
                height: 24px;
                fill: var(--WindowComponent-content-background);
            }
        }

        :global(.MuiTypography-caption) {
            opacity: .7;
        }

        .tracking_time {
            display: inline-block;
            color: rgba(0, 0, 0, .6);
            background-color: var(--color);
            border: 1px solid rgba(0, 0, 0, .2);
            padding: 2px 5px;
            font-size: 12px;
            font-weight: bold;
            border-radius: 10px;
        }
    }
}