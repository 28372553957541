.amount-costs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--action-disabled);
    background-color: rgba(0, 0, 0, .1);
    border-radius: 10px;

    >div {
        display: flex;
        flex-direction: row;
        width: 100%;
        padding: 15px;
        border: 0px solid var(--action-disabled);
        border-top-width: 1px;

        &:first-child {
            border-top-width: 0px;
        }

        >.label {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            margin-right: 20px;
            flex: 1;
        }

        >.value {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;
            text-align: right;
        }
    }
}