


.quadrado-inteiroO{
    background-color: var(--primary-dark) !important;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-left: 3px;
    margin-right: 3px;
    height: 25px;
}

.quadrado-inteiroO{
    background-color: rgba(0,0,0,.3) !important;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 25px !important;
   
}


.separandoBotoes{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 30px;
}
.ajustando-numeros{
    background-color: rgba(0,0,0,.3) !important;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 20px;
    padding: 2px;
    margin-left: 60px;
    margin-right: 60px;
}
.MuiTypography-root-ajustandoTipographyY{
    color: green !important;
}