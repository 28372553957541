.root {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .particles-container {
        position: absolute;
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
        overflow: hidden;
        // background: radial-gradient(circle, var(--WindowComponent-background) 10%, transparent 100%);

        >svg {
            position: absolute;
            width: 50px;
            height: 50px;
            transform: scale(0.8);
        }
    }

    .access-container {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;

        &:empty::after {
            content: "Carregando...";
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 60%;
            font-size: 20px;
            font-family: Arial, Helvetica, sans-serif;
            font-weight: bold;
            text-align: center;
            color: var(--text-primary);
            opacity: .6;
        }

        .btn {
            padding: 20px 60px;
            border: none;
            outline: none;
            z-index: 1;
            border-radius: 5px;
            background: linear-gradient(to right, #ffa000, #ffd54f, #ff8f00, #ffc107);
            background-size: 400% 400%;
            animation: movebackground 4s linear infinite;
            cursor: pointer;

            @keyframes movebackground {
                0% {
                    background-position: 0% 50%;
                }

                50% {
                    background-position: 100% 50%;
                }

                100% {
                    background-position: 0% 50%;
                }
            }

            &::before {
                content: "";
                position: absolute;
                left: 2px;
                right: 2px;
                top: 2px;
                bottom: 2px;
                border-radius: 4px;
                background-color: #191b1d;
                z-index: -1;
                transition: 200ms
            }

            &::after {
                content: attr(data-sala);
                font-size: 16px;
                font-weight: bold;
                background: linear-gradient(to right, #ffa000, #ffd54f);
                background-clip: text;
                color: transparent;
                transition: 200ms;
            }

            &:hover::before {
                opacity: .8;
            }

            &:disabled {
                filter: grayscale(1);
            }
        }

        .warning {
            position: absolute;
            bottom: 0px;
            left: 0px;
            right: 0px;
            background-color: rgb(255, 152, 0, .47);
            border-top: 2px solid rgba(255, 193, 7, 0.3);
            color: #FAFAFA;
            padding: 10px 15px;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: 1px;
            text-align: center;
            backdrop-filter: blur(15px);
            text-shadow: 2px 2px 9px #212121;
        }
    }

    .resume-container {
        background-color: var(--WindowComponent-content-border-color);

        >.banner {
            position: absolute;
            left: 0px;
            right: 0px;
            top: 0px;
            bottom: 0px;
            background-color: rgba(0, 0, 0, .2);
            z-index: 0;
            color: var(--text-primary);
            font-size: 20px;
            font-weight: bold;
            text-transform: uppercase;
            display: flex;
            align-items: center;
            justify-content: center;
            visibility: hidden;
        }

        >.resume {
            width: 100%;
            padding: 15px 10px;
            margin: 0px auto;
            z-index: 2;
            opacity: 1;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            >div {
                margin: 10px;
                flex: 1;

                >* {
                    white-space: nowrap;
                }
            }

            :global(.MuiTypography-body2) {
                opacity: .7;
                margin-bottom: 10px;
            }

            :global(.MuiTypography-h4) {
                font-weight: bold;

                >svg {
                    opacity: 1;
                    animation: blink 1.5s ease-in-out infinite alternate;

                    @keyframes blink {
                        0% {
                            opacity: 1;
                        }

                        100% {
                            opacity: .1;
                            color: #fafafa;
                        }
                    }
                }

                :global(.MuiTypography-h6) {
                    opacity: .7;
                    color: var(--text-primary);
                }
            }
        }

        &.loading {
            >.banner {
                visibility: visible;
                z-index: 2;
            }

            >.resume {
                z-index: 0;
                opacity: .05;
            }
        }
    }
}