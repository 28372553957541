.register {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--Settings-registerIP-background);
    border: solid 2px var(--Settings-registerIP-border);
    margin: 10px;
    border-radius: 5px;
    flex-wrap: wrap;
    height: calc(100% - 20px);

    >* {
        margin-top: 10px;
        margin-bottom: 10px;
        margin-right: 10px;
        margin-left: 10px;
    }

    &.register-active {
        border-color: rgba(0, 150, 136, 1);
        background-color: rgba(0, 150, 136, .1);
    }

    &.register-denied {
        border-color: rgba(244, 67, 54, 1);
        background-color: rgba(244, 67, 54, .1);
    }

    &.register-authorized {
        border-color: rgba(3, 169, 244, 1);
        background-color: rgba(3, 169, 244, .1);
    }

    .register-icon {
        width: 70px;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            width: 40px;
            height: 40px;
        }
    }

    .register-picture {
        margin: 0px 30px 0px 20px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, .4);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .register-label {
        flex: 1;
        text-align: left;

        >* {
            margin: 0px;
        }
    }

    >*:last-child {
        flex: 1;
    }
}

.access_permissions {
    text-align: center;
    padding: 15px 0px;

    &.error {
        color: var(--error-dark) !important;
    }

    &:empty::before {
        content: attr(empty-message);
        position: relative;
        text-align: center;
        opacity: .7;
        font-style: italic;
    }

    .register {
        padding-right: 30px;

        .actions {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: start;
            width: 30px;
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            padding: 5px 5px 0px 0px;
            margin: 0px;
        }
    }
}

.input_code_confirm {
    min-width: 200px;
    min-height: 50px;

    --fill: var(--Settings-registerIP-background);
    --stroke: var(--Settings-registerIP-border);

    path {
        fill: var(--fill);
        stroke: var(--stroke);
        stroke-linecap: round;
    }

    text {
        fill: currentColor;
        font-size: 25px;
        font-weight: bold;
        font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
        cursor: pointer;
        user-select: text;
    }

    &.error {
        --fill: transparent;
        --stroke: transparent;
        --text-primary: var(--error-dark);

        text {
            font-style: italic;
            opacity: .7;
            font-size: 20px;
            cursor: auto;
            user-select: none;
        }
    }
}

.access_info {
    .picture {
        text-align: center;
        margin-bottom: 40px;

        .img {
            width: 150px;
            height: 150px;
            border-radius: 50%;
            background-color: rgba(0, 0, 0, .4);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            margin: 0px auto 15px;
            box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
        }
    }

    :global(.MuiTypography-body2) {
        background-color: rgba(0, 0, 0, .1);
        border: 2px solid rgba(0, 0, 0, .1);
        padding: 10px 15px;
        border-radius: 10px;
    }

    .contentInformation {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
        margin: 8px 0px;

        >* {
            margin-right: 10px;
        }

        >*:last-child {
            flex: 1;
            margin-right: 0px;
        }
    }
}

.Bep20TrustList {
    display: flex;
    flex-direction: column;
    padding: 15px 10px;

    >div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 0px 10px;
        border-radius: 5px;
        border: 1px solid var(--Settings-registerIP-border);
        background-color: rgba(0, 0, 0, .1);
        margin: 5px 0px;
        gap: 20px;

        &.valid {
            border-color: rgba(3, 169, 244, 1);
            background-color: rgba(3, 169, 244, .1);
        }
    }
}