.ModeThemeSwitch-root{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
}

.ModeThemeSwitch-root input[type="checkbox"]{
    display:none;
}

.ModeThemeSwitch-root label{
    background-color: rgba(0, 0, 0, .1);
    border-radius: 30px;
    padding: 2px;
    cursor: pointer;
    display: flex;
}

.ModeThemeSwitch-root .switch-button:checked ~ label{
    background-color: rgba(255, 255, 255, .1);
}

.ModeThemeSwitch-root .fa-sun,
.ModeThemeSwitch-root .fa-moon{
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, .5);
    border-radius: 50%;
    transform-origin: center;
    transition: .3s;
}

.ModeThemeSwitch-root.ModeThemeSwitch-size-small .fa-sun,
.ModeThemeSwitch-root.ModeThemeSwitch-size-small .fa-moon{
    width: 22px;
    height: 22px;
}

.ModeThemeSwitch-root.ModeThemeSwitch-size-large .fa-sun,
.ModeThemeSwitch-root.ModeThemeSwitch-size-large .fa-moon{
    width: 44px;
    height: 44px;
}

.ModeThemeSwitch-root .fa-sun > svg,
.ModeThemeSwitch-root .fa-moon > svg{
    width: 24px;
    height: 24px;
}

.ModeThemeSwitch-root.ModeThemeSwitch-size-small .fa-sun > svg,
.ModeThemeSwitch-root.ModeThemeSwitch-size-small .fa-moon > svg{
    width: 14px;
    height: 14px;
}

.ModeThemeSwitch-root.ModeThemeSwitch-size-large .fa-sun > svg,
.ModeThemeSwitch-root.ModeThemeSwitch-size-large .fa-moon > svg{
    width: 34px;
    height: 34px;
}

.ModeThemeSwitch-root .fa-sun{
    color: orange;
    text-shadow: 0px 0px 20px orange;
    transform: translateX(35px);
}

.ModeThemeSwitch-root.ModeThemeSwitch-size-small  .fa-sun{
    transform: translateX(22px);
}

.ModeThemeSwitch-root.ModeThemeSwitch-size-large  .fa-sun{
    transform: translateX(44px);
}

.ModeThemeSwitch-root  .fa-moon{
    color: white;
    text-shadow: 0px 0px 20px white;
    transform: translateX(0px) rotate(0);
    opacity: 0;
}

.ModeThemeSwitch-root .switch-button:checked ~ label > .fa-sun{
    opacity: 0;
    transform: translateX(0px);
}

.ModeThemeSwitch-root .switch-button:checked ~ label > .fa-moon{
    opacity:1;
    transform: translateX(-34px) rotate(35deg);
}

.ModeThemeSwitch-root.ModeThemeSwitch-size-small .switch-button:checked ~ label > .fa-moon{
    transform: translateX(-21px) rotate(35deg);
}

.ModeThemeSwitch-root.ModeThemeSwitch-size-large .switch-button:checked ~ label > .fa-moon{
    transform: translateX(-43px) rotate(35deg);
}