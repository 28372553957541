.main{
    display: flex;
    flex-direction: row;
    flex: 1;

    > div{
        flex: 1;

        &.balance{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            overflow: hidden;
            margin: 0px;
            height: auto;

            @media only screen and (max-width: 830px){
                &{
                    min-height: 550px;
                }
            }

            > .background-chart{
                position: absolute;
                top: 0px;
                left: 0px;
                right: 0px;
                bottom: 0px;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: right bottom;
                background-color: var(--WindowComponent-background);
                border-radius: 0px 10px 10px 0px;
            }

            > .loading{
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                width: 100%;
                flex: 1;
                color: var(--text-primary);
                border-radius: 0px 10px 10px 0px;
            }

            > .balance-steps{
                width: 100%;
                padding: 10px;
                z-index: 9;
                padding: 20px 20px 10px 20px;
                flex-wrap: nowrap;
                overflow-x: auto;
                overflow-y: hidden;

                &::-webkit-scrollbar{
                    display: none;
                }

                @media only screen and (max-width: 650px){
                    :global(span.MuiStepLabel-labelContainer > span.MuiStepLabel-alternativeLabel){
                        display: none;
                    }
                }
            }

            > .balances-carousel{
                overflow: hidden;
                flex: 1;
                width: 100%;
                height: auto;

                > .balance-body{
                    position: absolute;
                    top: 0px;
                    bottom: 0px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    z-index: 6;
                    transition: left 1s cubic-bezier(0.59, 0.01, 0, 1.66);

                    > div{
                        width: 95%;
                        max-width: 400px;
                        height: 90%;
                        max-height: 500px;
                        text-align: center;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-between;
                        background-color: var(--Presale-Widget-balance-carousel-cart-background);
                        border-radius: 10px;
                        backdrop-filter: blur(20px);
                        box-shadow: 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12), 0 5px 5px -3px rgba(0, 0, 0, .2);
                        padding: 25px 15px;

                        > .currency-change{
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            align-items: center;

                            > .linear-progress{
                                width: 100%; 
                                height: 10px; 
                                margin: 10px 0px;
                                border-radius: 5px;
                            }
                        }

                        > .details{
                            background-color: var(--Presale-Widget-balance-carousel-cart-details);
                            padding: 10px;
                            border-radius: 10px;
                            border: solid 2px rgba($color: #000000, $alpha: .1);
                        }

                        > .countdown{
                            opacity: .6;
                            cursor: pointer;
                            color: var(--text-primary);
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;

                            &:hover{
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }

        &.account{
            width: 500px;
            max-width: 45%;
            margin: 5px;
            padding: 15px;
            border-radius: 10px;
            overflow: hidden;
            overflow-y: auto;
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            align-items: center;

            &:empty::after{
                content: "Esperando dados da sua carteira...";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 60%;
                font-size: 18px;
                font-family: Arial, Helvetica, sans-serif;
                font-weight: bold;
                text-align: center;
                color: var(--text-primary);
                opacity: .2;
            }

            .WalletActions{
                width: 100%;
                display: flex;
                justify-content: center;
                margin-bottom: 25px;

                :global(.MuiButtonGroup-root){
                    background: var(--Wallets-infoContent-background);
                    border: 2px solid var(--Wallets-infoContent-border);
                    backdrop-filter: blur(35px);

                    > button{
                        background-color: transparent;
                        color: var(--text-primary) !important;
                        border-color: var(--Wallets-infoContent-border);
                        padding-left: 25px;
                        padding-right: 25px;

                        &:not(:last-of-type){
                            border-right-width: 2px;
                        }

                        &:global(.Mui-disabled){
                            color: var(--action-disabled) !important;
                        }
                    }
                }
            }

            .WalletBalances{
                display: flex;
                flex-direction: column;
                width: 100%;
                flex: 1;
                overflow-y: auto;

                :global(.MuiPaper-root){
                    width: 100%;
                    min-height: 100%;
                    height: auto;
                    background: transparent;
                    border: 2px solid var(--WindowComponent-content-background);
                    border-radius: 10px;

                    *{
                        user-select: text !important;
                    }

                    :global(.MuiTableCell-root){
                        border-bottom: 2px solid var(--WindowComponent-content-background);
                    }
                }
            }
        }
    }
}