.main{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    max-height: 100%;
    padding: 15px;

    & > *:last-child{
        margin-top: 10px;
        margin-bottom: 20px;
    }

    * {
        color: var(--text-primary);
        font-weight: bold;

        &:global(.MuiTypography-h5){
            opacity: .9;
        }
    }

    .banner{
        width: 100%;
        flex: 1;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        background-color: transparent;
        max-height: 255px;
        margin-bottom: 20px;
        filter: drop-shadow(0 2px 15px rgba(0, 0, 0, 0.2));
    }
}