.main {
    min-width: 300px;
    background-color: rgba(0, 0, 0, 0.3);
    height: 20px;
    border-radius: 10px;
    margin: 15px auto;

    .achievements {
        position: absolute;
        left: 0px;
        top: 0px;
        right: 0px;
        height: 0px;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
        top: 50%;
        transform: translateY(-50%);
        height: auto;

        .progressBar {
            --progress: 25%;
            display: flex;
            flex-direction: column;
            padding: 4px;
            flex: 1;
            height: 20px;

            &::after {
                content: '';
                position: relative;
                width: var(--progress);
                min-width: 12px;
                height: 100%;
                background-color: #ED8A19;
                border-radius: 10px;
            }
        }

        >div:not(.progressBar) {
            width: 40px;
            height: 40px;
            background-color: rgba(var(--color), 1);
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;

            >svg {
                width: 35px;
                height: 35px;
                margin-top: -10px;
                fill: rgba(0, 0, 0, 0.3);
            }

            &.active {
                >svg {
                    opacity: 1;
                    fill: #D8D8D8;
                }
            }
        }
    }
}