.ivip-coin{
    width:250px;
    height:100px;
    background-color: red;
    margin-left:80px;
    margin-top:50px;
    margin-bottom:50px;
}
.mover-griD-froM-scren-Two{
    margin-top: 20px;
 }
 .coMprar-ANd-venDer{
    display: flex;
    justify-content: space-between;
    margin-top: -16px;
    background-color:#33395F;
    border-radius:5px 5px 0px 0px; 
    margin-bottom:20px;
}
.divisao{
    width:2px;
    height:50px;
    background-color: black;
    margin-left: 138px;
    margin-top:-30px;
    margin-bottom: 10px;
}

.coMprar-aNd-venDer{
    display: flex;
    justify-content: space-between;
    margin-top: -16px;
    background-color: #33395F;
    border-radius:10px 10px 0px 0px; 
}
.CoMprar-up{ 
    margin-top: -5px;
    border-radius:1px;      
}
.CoMprar-up:hover{
    height:29px;
    margin-top: 1px;
    border-radius:10px 0px 0px 0px; 
    background-color:rgba(255, 255, 255, 0.2)!important;
}
.CoMprar-up button{
    background-color:transparent !important;
}
.VeNder-up{
    margin-top: -5px;
    border-radius:1px;
}
.VeNder-up:hover{
    height:29px;
    margin-top: 1px;
    border-radius:0px 10px 0px 0px; 
    background-color:rgba(255, 255, 255, 0.2)!important;
}
.VeNder-up button{
    background-color:transparent !important;
}
.vaRiacao{
    display: flex;
    flex-direction: row;
    justify-content:center;
    margin-top:30px;
    padding:20px;
    margin-bottom: 20px;
}
.vaRiacoes{
    display: flex;
    flex-direction: row;
    justify-content:center;
    padding:20px;
    margin-bottom: 20px;
}
.biticON-wiTh-usdt{
    background-color:#212647; 
    width:275px;
    border-radius:5px;
}
.bitecon{
    background-color:#212647; 
    width:120px;
    height:105px;
    border-radius:5px;
  
}
.numero-da-ivip{
    margin-top:10px;
    color:white;
    
}
.numero-da-ivip-um{
    color:white;
    margin-left: 10px;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    font-size: 12px;
}
.numero-da-ivip-two{
    color:white;
    margin-left: 10px;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    font-size: 12px;
}
.numero-da-ivip-tres{
    color:white;
    margin-left: 10px;
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    font-size: 12px;
}
.numeros-da-ivip{
    margin-top:10px;
    color:white;
    margin-left: 75px;
    margin-top: -43px;
}
.numero-da-bnb{
    margin-top:10px;
    color:white;
    margin-left: 50px;
}
.numeros-da-bnb{
    margin-top:10px;
    color:white;
    margin-right: 55px;
    margin-top: -43px;
}
.unificando-ivip-bnb{
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.unificando-Ivip-Bnb{
    display: flex;
    flex-direction: row;
    justify-content: space-around;

}
.vaR{
    display: flex;
    flex-direction: row;
    justify-content:center;
    margin-top:-70px;
    padding:20px;
    margin-bottom: 20px;
}

.unificando-ivipbnb{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.numero-da-ivip-one{
    margin-top:10px;
    color:white;
    margin-left: 10px;
}
.numero-da-bnb-one{
    margin-top:10px;
    color:white;
    margin-left: 55px;
}
.iviPay{ 
    margin-left:30px;     
}
.unindo-ivip-ivipay{
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.butocoes{
    margin-right:8px;
    margin-top: -16px;
}
.depositar{
    background-color:#212647; 
    width: 100px;
    border-radius:10px;
}
.leilão{
    background-color:#212647; 
    width: 100px;
    margin-top:5px;
    text-align:center;
    border-radius:10px;
}
.sacar{
    background-color:#212647; 
    width: 100px;
    margin-top:5px;
    text-align:center;
    border-radius:10px;
}
.iviPay:hover{  
    background-color:rgba(255, 255, 255, 0.2)!important;
}
.iviPay button{
    background-color:transparent !important;
}
.depositar:hover{
    
    background-color:rgba(255, 255, 255, 0.2)!important;
}
.depositar button{
    background-color:transparent !important;
}
.leilão:hover{
    
    background-color:rgba(255, 255, 255, 0.2)!important;
}
.leilão button{
    background-color:transparent !important;
}
.sacar:hover{
    
    background-color:rgba(255, 255, 255, 0.2)!important;
}
.sacar button{
    background-color:transparent !important;
}