.profilePicture-main{
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    overflow: hidden;
}

.profilePicture-main > .profilePicture-insignia{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    opacity: .9;
}

.profilePicture-main > .profilePicture-insignia > svg{
    width: 100%;
    height: 100%;
}

.profilePicture-main > .profilePicture-insignia > svg > g > .cls-1,
.profilePicture-main > .profilePicture-insignia > svg > g > .cls-2{
    fill: none;
    stroke-linecap: round;
    stroke-miterlimit: 10;
    stroke-dasharray: 149.23;
    stroke-dashoffset: 0;
}

.profilePicture-main > .profilePicture-insignia > svg > g > .cls-1{
    stroke: var(--text-primary);
    stroke-width: 8px;
    opacity: .8;
}

.profilePicture-main > .profilePicture-insignia > svg > g > .cls-2{
    stroke: var(--WindowComponent-background);
    stroke-width: 3px;
}

.profilePicture-main > .profilePicture-insignia > svg > g > .cls-3,
.profilePicture-main > .profilePicture-insignia > svg > g > .cls-4{
    fill: var(--text-primary);
    font-size: 10px;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.profilePicture-main > .profilePicture-insignia > svg > g > .cls-4{
    font-weight: bold;
}

.profilePicture-main > .profilePicture-content{
    position: relative;
    width: 50px;
    height: 50px;
    background-color: #424242;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
}

.profilePicture-main > .profilePicture-content > .profilePicture-btn,
.profilePicture-main > .profilePicture-content > .profilePicture-loading{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    cursor: pointer;
    opacity: 0;
    transition: all .2s ease-in;
}

.profilePicture-main > .profilePicture-content > .profilePicture-btn > svg{
    width: 45%;
    max-width: 35px;
    height: 45%;
    max-height: 35px;
}

.profilePicture-main > .profilePicture-content:hover > .profilePicture-btn{
    opacity: 1;
}

.profilePicture-main > .profilePicture-content > .profilePicture-loading{
    opacity: 1;
}

.profilePicture-main > .profilePicture-content > .profilePicture-loading *{
    color: #ffffff;
}