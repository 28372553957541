.CropImage-main{
	position: relative;
	width: 100%;
	height: auto;
	min-height: 250px;
	user-select: none;
}

.CropImage-main > .CropImage-container{
	position: absolute;
	width: 100%;
	height: 100%;
	background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQAQMAAAAlPW0iAAAAA3NCSVQICAjb4U/gAAAABlBMVEXMzMz////TjRV2AAAACXBIWXMAAArrAAAK6wGCiw1aAAAAHHRFWHRTb2Z0d2FyZQBBZG9iZSBGaXJld29ya3MgQ1M26LyyjAAAABFJREFUCJlj+M/AgBVhF/0PAH6/D/HkDxOGAAAAAElFTkSuQmCC');
	overflow: hidden;
}

.CropImage-main > .CropImage-container > *{
	opacity: 1;
}

.CropImage-main > .CropImage-container.loading > *{
	opacity: 0;
}

.CropImage-main > .CropImage-container > .CropImage-wrap{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
	width: auto;
	height: auto;
}

.CropImage-main > .CropImage-container > .CropImage-wrap > .CropImage-canvas{
    position: absolute;
    top: 0;
    left: 0;
	width: auto;
	height: auto;
	background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
}

.CropImage-main > .CropImage-container > .CropImage-drag{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
	width: auto;
	height: auto;
	background-color: #000;
    opacity: 0.5;
}

.CropImage-main > .CropImage-container > .CropImage-crop{
    position: absolute;
    top: 0;
    left: 0;
	width: auto;
	height: auto;
}

.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-view{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
	width: auto;
	height: auto;
	overflow: hidden;
}

.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-view::after{
	content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
	width: auto;
	height: auto;
	border: 1px solid rgba(51, 153, 255, 0.75);
}

.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-view > .CropImage-canvas{
    position: absolute;
    top: 0;
    left: 0;
	width: auto;
	height: auto;
	background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-color: transparent;
}

.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-dashed{
	border: 0 dashed #eee;
    display: block;
    opacity: 0.5;
    position: absolute;
}

.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-dashed.dashed-h{
	border-bottom-width: 1px;
    border-top-width: 1px;
    height: 33.34%;
    left: 0;
    top: 33.34%;
    width: 100%;
}

.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-dashed.dashed-v{
	border-left-width: 1px;
    border-right-width: 1px;
    height: 100%;
    left: 33.34%;
    top: 0;
    width: 33.34%;
}

.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-center {
    display: block;
    height: 0;
    left: 50%;
    opacity: 0.75;
    position: absolute;
    top: 50%;
    width: 0;
}

.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-center::before, .CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-center::after{
	background-color: #eee;
    content: ' ';
    display: block;
    position: absolute;
    box-sizing: border-box;
}

.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-center::before{
	height: 1px;
    left: -3px;
    top: 0;
    width: 7px;
}

.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-center::after{
	height: 7px;
    left: 0;
    top: -3px;
    width: 1px;
}

.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-face, .CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-line, .CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-point {
    display: block;
    height: 100%;
    opacity: 0.1;
    position: absolute;
    width: 100%;
}

.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-face {
    background-color: transparent;
    left: 0;
    top: 0;
}

.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-move{
    cursor: move;
}

.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-line {
    background-color: #39f;
}

.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-line.line-e {
    cursor: ew-resize;
    right: -3px;
    top: 0;
    width: 5px;
}

.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-line.line-n {
    cursor: ns-resize;
    height: 5px;
    left: 0;
    top: -3px;
}

.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-line.line-w {
    cursor: ew-resize;
    left: -3px;
    top: 0;
    width: 5px;
}

.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-line.line-s {
    bottom: -3px;
    cursor: ns-resize;
    height: 5px;
    left: 0;
}

.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-point {
    height: 5px;
    width: 5px;
    opacity: 1;
}

.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-point.point-e,
.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-point.point-n,
.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-point.point-w,
.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-point.point-s{
    border-radius: 10px;
    background-color: #39f;
}

.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-point.point-e,
.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-point.point-w{
    height: 25px;
}

.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-point.point-n,
.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-point.point-s{
    width: 25px;
}

.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-point.point-e {
    cursor: ew-resize;
    right: 0px;
    top: 50%;
    transform: translate(50%, -50%);
}

.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-point.point-n {
    cursor: ns-resize;
    left: 50%;
    margin-left: -3px;
    top: 0px;
    transform: translate(-50%, -50%);
}

.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-point.point-w {
    cursor: ew-resize;
    left: 0px;
    margin-top: 0px;
    top: 50%;
    transform: translate(-50%, -50%);
}

.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-point.point-s {
    bottom: 0px;
    cursor: ns-resize;
    left: 50%;
    margin-left: 0px;
    transform: translate(-50%, 50%);
}

.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-point.point-ne::before,
.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-point.point-ne::after,
.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-point.point-nw::before,
.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-point.point-nw::after,
.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-point.point-sw::before,
.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-point.point-sw::after,
.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-point.point-se::before,
.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-point.point-se::after{
	content: "";
	position: absolute;
    border-radius: 10px;
    background-color: #39f;
    height: 5px;
    width: 5px;
}

.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-point.point-ne {
    cursor: nesw-resize;
    right: 0px;
    top: 0px;
}

.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-point.point-ne::before{
	height: 15px;
	top: -2px;
	right: 0px;
	transform: translate(50%, 0%);
}

.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-point.point-ne::after{
	width: 15px;
	top: 0px;
	right: -2px;
	transform: translate(0%, -50%);
}

.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-point.point-nw {
    cursor: nwse-resize;
    left: 0px;
    top: 0px;
}

.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-point.point-nw::before{
	height: 15px;
	top: -2px;
	left: 0px;
	transform: translate(-50%, 0%);
}

.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-point.point-nw::after{
	width: 15px;
	top: 0px;
	left: -2px;
	transform: translate(0%, -50%);
}

.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-point.point-sw {
    cursor: nesw-resize;
    bottom: 0px;
    left: 0px;
}

.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-point.point-sw::before{
	height: 15px;
	bottom: -2px;
	left: 0px;
	transform: translate(-50%, 0%);
}

.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-point.point-sw::after{
	width: 15px;
	bottom: 0px;
	left: -2px;
	transform: translate(0%, 50%);
}

.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-point.point-se {
    cursor: nwse-resize;
    bottom: 0px;
    right: 0px;
}

.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-point.point-se::before{
	height: 15px;
	bottom: -2px;
	right: 0px;
	transform: translate(50%, 0%);
}

.CropImage-main > .CropImage-container > .CropImage-crop > .CropImage-point.point-se::after{
	width: 15px;
	bottom: 0px;
	right: -2px;
	transform: translate(0%, 50%);
}