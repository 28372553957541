.main-contant {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100%;

    >.tools-bar {
        padding: 5px 15px;
    }

    >.contant {
        flex: 1;
        overflow: hidden;
    }

    .tradingview-widget-container {
        height: 100%;

        >div {
            height: 100%;
        }
    }
}