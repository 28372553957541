.input_amount {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    user-select: none;

    .content {
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        background-color: rgba(0, 0, 0, 0.1);
        border: 2px solid rgba(0, 0, 0, 0.1);
        width: 100%;
        max-width: 400px;

        &.warn {
            .display {
                border-color: var(--warning-main);
            }

            .digits {
                border-top-width: 0px;
                border-bottom-width: 0px;
                border-left-color: var(--warning-main);
                border-right-color: var(--warning-main);
                border-radius: 0px;
            }
        }

        .display {
            display: flex;
            flex-direction: row;
            border-radius: 10px 10px 0px 0px;
            border: 2px solid rgba(0, 0, 0, 0.1);
            overflow: hidden;

            .currency {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;
                font-size: 1.5rem;
                font-weight: 600;
                color: var(--text-primary);
                border-right: 2px solid rgba(0, 0, 0, 0.1);

                >div {
                    margin-right: 10px;
                }
            }

            .input {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding: 10px 12px 10px 10px;
                text-align: right;
                color: var(--text-primary);
                overflow-x: auto;
                user-select: text;
                cursor: text;

                &::-webkit-scrollbar {
                    display: none;
                }

                >div {
                    display: inline-block;
                }

                >.value {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 1.5rem;
                    font-weight: 600;
                }

                >.cursor {
                    position: absolute;
                    top: 50%;
                    right: 6px;
                    transform: translateY(-50%);
                    margin: 0px 2px;
                    width: 2px;
                    height: 1.7rem;
                    background-color: var(--text-primary);
                    animation: blink 1s infinite;
                    border-radius: 1px;

                    @keyframes blink {
                        0% {
                            opacity: 1;
                        }

                        50% {
                            opacity: 0;
                        }

                        100% {
                            opacity: 1;
                        }
                    }
                }
            }
        }

        .warning {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px;
            font-size: 1rem;
            font-weight: 600;
            color: var(--text-primary);
            background-color: var(--warning-main);
            background-image: linear-gradient(var(--action-light-active), var(--action-light-active));
            border: 2px solid var(--warning-main);
            border-radius: 0px 0px 10px 10px;
        }

        .digits {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            border: 2px solid rgba(0, 0, 0, 0.1);
            border-radius: 0px 0px 10px 10px;
            overflow: hidden;

            >div {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 33.3333%;
                padding: 10px;
                font-size: 1.5rem;
                font-weight: 600;
                color: var(--text-primary);
                border-right: 2px solid rgba(0, 0, 0, 0.1);
                border-bottom: 2px solid rgba(0, 0, 0, 0.1);
                cursor: pointer;
                user-select: none;

                &:hover {
                    background-color: rgba(0, 0, 0, 0.05);
                }

                &:focus {
                    background-color: rgba(0, 0, 0, 0.1);
                }

                &:active {
                    background-color: rgba(0, 0, 0, 0.15);
                }

                >svg {
                    width: 20px;
                    height: 20px;
                }

                &:nth-child(3n) {
                    border-right: none;
                }

                &:nth-last-child(-n+3) {
                    border-bottom: none;
                }
            }
        }

        &.disabled {
            .digits {
                >div {
                    opacity: 0.6;
                    cursor: auto;

                    &:hover,
                    &:focus,
                    &:active {
                        background-color: transparent;
                    }
                }
            }
        }
    }
}