.MuiGrid-root-quadrado-copleto{
    border-radius: 0px !important;
}
.segundoquadrado{
    background-color: rgb(119, 112, 112);
    width: 220px;
    height: 120px;
    margin-top: -105px;

}
.MuiItem-root-envonte{
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.MuiButton-root-teste-one{
    margin-left: 100px !important;
}
.MuiTypography-root-binance{
    background-color: rgba(0,0,0,.3) !important;
    margin-right: 110px !important;
    margin-top: -30px !important;
    width: 50% !important;
    border-radius: 5px 0px 0px 0px !important;
    color:white !important;
    height: 24px !important;
}
.MuiTypography-root-metamask{
    background-color: rgba(0,0,0,.3) !important;
    width: 50% !important;
    margin-left: 110px !important;
    border-radius: 0px 5px 0px 0px !important;
    color: white !important;
    height: 24px !important;
    margin-top: -25px !important;
}
.cateira-iVip{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    color:white;
    margin-top: 32px;
}
.cateira-iVip-bnb{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    color: white;
    margin-top: 15px;
}
.carteira-ivip-bnb{
    background-color: rgb(63, 62, 62);
    border-radius: 5px;
    padding: 2px;

}
.deposita-leilao-sacar{
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    margin-right: 110px;
    margin-top: 2px;
}
.MuiButton-root-metamaSkK{
    background-color: var(--primary-dark) !important;
    color:white !important;
}
.MuiButton-root-metamAskK{
    background-color: var(--primary-dark) !important;
    color:white !important;
    margin-top: 10px !important;
}
.MuiButton-root-metamaskK{
    background-color: var(--primary-dark) !important;
    color:white !important;
    margin-top: 10px !important;
}
.ultimo-quadrado{
    background-color: rgb(119, 112, 112);
    width: 100px;
    height: 128px;
   margin-left: 120px;
   margin-top: -128px;
   border-radius: 5px;
}
.MuiButton-root-ivipay-ultimo-quadrado{
    background-color: var(--primary-dark) !important;
    width: 100% !important;
    color: white !important;
}

.quadrado-inteiro{
        background-color: rgba(0,0,0,.3) !important;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    height: 25px !important;
   
}
.MuiButton-root-cateira-vip{
    color: white !important;
    border-radius: 5px;
}
.MuiButton-root-cateira-vip-Pay{
    color: white !important;
}