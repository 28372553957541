.root {
    padding: 15px;

    .values-swap {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 35px;
        border: solid 1px var(---divider);
        background-color: rgba(0, 0, 0, .01);
        border-radius: 10px;
        overflow: hidden;
        padding: 0px 5px;
        align-items: center;

        >div.input-arrow-icon {
            margin-top: -15px;
            margin-bottom: -15px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            background-color: var(--background-paper);
            background-image: linear-gradient(var(--action-selected), var(--action-selected));
            height: 30px;
            width: 30px;
            border-radius: 50%;
            border: 2px solid var(--action-hover);
            transition: border-color 0.1s ease-in-out;
            z-index: 1;

            >svg {
                fill: var(--text-primary);
                height: 20px;
                width: 20px;
                transform: rotate(90deg);
            }
        }

        .input-base {
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            border-radius: 0px;
            width: 100%;
            border-radius: 10px;
            background-color: var(--action-hover);
            border: 2px solid var(--action-hover);
            margin: 5px 0px;

            >.input-symbol {
                margin: 10px 15px 5px 10px;
                height: 60px;
                width: 60px;
            }

            >.input-base-by {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 5px 0px 0px;

                > :global(.MuiInputBase-root) {
                    flex: 1;
                    padding-right: 20px;

                    input {
                        margin: 0px;
                        font-size: 25px;
                    }

                    input::-webkit-outer-spin-button,
                    input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                    }

                    input[type=number] {
                        -moz-appearance: textfield;
                    }
                }
            }

            >p.balance {
                font-style: italic;
                opacity: .8;
                width: 100%;
                padding: 0px 15px 4px;
            }
        }
    }
}