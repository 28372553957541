@font-face {
	font-family: "Emoji";
	font-style: normal;
	src: url(./Fonts/NotoColorEmoji.ttf) format("truetype");
}

* {
	-webkit-app-region: no-drag;
	position: relative;
	box-sizing: border-box;
	scroll-behavior: smooth;
}

html,
body {
	margin: 0;
	padding: 0;
	width: 100vw;
	height: 100%;
	max-height: 100%;
}

body {
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif, Emoji;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	user-select: none;
	background-size: 30%;
	background-color: #424242;
}

.select-text,
.select-text * {
	user-select: text !important;
}

body.drag-enable,
body.drag-enable * {
	-webkit-app-region: drag !important;
}

body.drag-disable,
body.drag-disable * {
	-webkit-app-region: no-drag;
}

:root {
	--background-noise: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMDAiIGhlaWdodD0iMzAwIj48ZmlsdGVyIGlkPSJhIiB4PSIwIiB5PSIwIj48ZmVUdXJidWxlbmNlIGJhc2VGcmVxdWVuY3k9Ii43NSIgc3RpdGNoVGlsZXM9InN0aXRjaCIgdHlwZT0iZnJhY3RhbE5vaXNlIi8+PGZlQ29sb3JNYXRyaXggdHlwZT0ic2F0dXJhdGUiIHZhbHVlcz0iMCIvPjwvZmlsdGVyPjxwYXRoIGQ9Ik0wIDBoMzAwdjMwMEgweiIgZmlsdGVyPSJ1cmwoI2EpIiBvcGFjaXR5PSIuMDUiLz48L3N2Zz4=);
}

body > #root {
	flex: 1;
	display: flex;
	flex-direction: column;
	height: 100%;
}

body > *:not(#root) {
}

a {
	color: var(--primary-main);
	text-decoration: none;
}

a:hover {
	color: var(--primary-dark);
	text-decoration: underline;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.css-ptr096-MuiBackdrop-root,
.css-zw3mfo-MuiModal-root-MuiDialog-root {
	/* -webkit-backdrop-filter: blur(4px); */
	/* backdrop-filter: blur(4px); */
	border-radius: 0px;
	overflow: hidden;
}

.css-zw3mfo-MuiModal-root-MuiDialog-root > div {
	border-radius: 0px;
}

body.isMaximized .css-ptr096-MuiBackdrop-root,
body.isMaximized .css-zw3mfo-MuiModal-root-MuiDialog-root,
body.isMaximized .css-zw3mfo-MuiModal-root-MuiDialog-root > div {
	border-radius: 0px !important;
}

.css-ptr096-MuiBackdrop-root::before {
	content: "";
	position: absolute;
	top: -5%;
	left: -5%;
	width: 110%;
	height: 110%;
	background-color: rgba(33, 33, 33, 0.6);
	backdrop-filter: blur(5px);
	display: none;
}

.MuiList-root > .MuiMenuItem-root {
	border: 1px solid transparent;
	border-radius: 5px;
	margin: 4px 2px;
}

.MuiList-root > .MuiMenuItem-root.Mui-selected {
	border: 1px solid var(--primary-main);
}

::-webkit-scrollbar {
	width: 10px;
	height: 10px;
}

::-webkit-scrollbar-track {
	background-color: transparent;
	border-radius: 0px;
}

::-webkit-scrollbar-track:hover {
	background-color: transparent;
}

::-webkit-scrollbar-thumb {
	background-color: rgba(0, 0, 0, 0.3);
	border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
	background-color: rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-corner {
	background-color: transparent;
}
