.container{
    width: 100%;
    max-width: 800px;
    height: 100%;
    margin: 15px auto;

    .view{
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 0;
        min-height: 100%;
        padding: 0px 15px 15px;

        *{
            user-select: text;
        }

        .toolbar{
            margin: 15px 0px;
            padding: 10px 15px;
            border: solid 2px rgba(0, 0, 0, .3);
            background-color: rgba(0, 0, 0, .1);
            border-radius: 5px;
            overflow: hidden;
            overflow-x: auto;

            ol{
                flex-wrap: nowrap !important;

                li:last-child{
                    padding-right: 25px;
                }
            }
        }

        .main{
            flex: 1;
            overflow: auto;
            border: solid 2px rgba(0, 0, 0, .3);
            border-radius: 5px;
            background-color: rgba(0, 0, 0, .02);

            .node-tree{
                display: flex;
                flex-direction: column;
                width: 100%;
                height: auto;

                & > div{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    flex-wrap: nowrap;

                    .node-sibling{
                        width: 32px;
                        min-width: 32px;
                        min-height: 32px;
                        position: relative;
                        flex: 0 1;
                        align-self: stretch;

                        &::before, &::after{
                            content: "";
                            position: absolute;
                            border: solid 0px var(--BDManager-nodeTree-sibling-background);
                            z-index: 1;
                        }

                        &::before{
                            top: 0px;
                            left: 13px;
                            bottom: 0px;
                            width: 0px;
                            height: auto;
                            border-left-width: 1px;
                        }

                        &::after{
                            top: 50%;
                            left: 14px;
                            right: 0px;
                            width: auto;
                            height: 0px;
                            border-bottom-width: 1px;
                        }

                        .node-sibling-object{
                            position: absolute;
                            left: 13px;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 15px;
                            height: 15px;
                            background-color: var(--WindowComponent-background);
                            border-radius: 50%;
                            z-index: 2;

                            & > svg{
                                color: var(--BDManager-nodeTree-sibling-background);
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }

                    &:last-child{
                        .node-sibling{
                            &::before{
                                bottom: calc(50% - 1px);
                            }
                        }
                    }
                }

                .node-view{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    flex-wrap: nowrap;
                    width: auto;
                    margin-right: auto;

                    &:hover > .node-view-child{
                        border-color: var(--BDManager-nodeTree-sibling-background);
                    }

                    & > .node-view-child{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        flex-wrap: nowrap;
                        padding: 0px 10px;
                        border-radius: 5px;
                        border: solid 1px transparent;
                        
                        & > *{
                            color: var(--text-secondary);
                            padding-right: 4px;
                            padding: 3px 4px 4px 0px;
                            white-space: nowrap;
                        }

                        .node-key{
                            &:hover{
                                color: var(--info-main);
                            }
                        }

                        .node-value{
                            min-width: 40px;
                            color: var(--text-primary);
                            cursor: pointer;
                            border: solid 1px transparent;
                            padding-left: 5px;
                            padding-right: 5px;

                            &:hover{
                                border-color: var(--BDManager-nodeTree-sibling-background);
                            }
                        }
                    }

                    &:hover > .node-view-actions{
                        opacity: 1;
                    }

                    & > .node-view-actions{
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        flex-wrap: nowrap;
                        padding: 0px 10px 0px 5px;
                        opacity: 0;

                        & > *{
                            opacity: .5;

                            &:hover{
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }
}