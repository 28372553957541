$medium: 1200px;

.Splash-root {
    display: flex;
    flex-direction: row;
    padding: 0px;
    flex: 1;
    overflow: hidden;
    height: 100%;

    & * {
        transition: all .2s ease-in;
    }

    &>.Splash-main {
        flex: 1;
        background-color: transparent;
        border-radius: 10px;
        overflow: hidden;

        .logo_marca_animation {
            width: 100%;
            height: 100%;
            color: #212121;
            --logo-color: #ff6d00;

            * {
                stroke-width: 0px;
                stroke: var(--logo-color);
                fill: var(--logo-color);
            }

            .blur-shadow {
                z-index: 0 !important;
                opacity: .5;
                stroke-dasharray: 10% 0%;
                stroke-dashoffset: 20%;
                filter: drop-shadow(0px 0px 2px currentColor) drop-shadow(0px 0px 8px currentColor) drop-shadow(0px 0px 16px currentColor) drop-shadow(0px 0px 28px currentColor) drop-shadow(0px 0px 20px currentColor);
            }
        }
    }

    &>.Splash-content {
        overflow: auto;
        opacity: 0;
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 0px;
        padding-bottom: 10px;
    }

    &.Splash-login> {
        .Splash-main {
            flex: inherit;
            background-color: rgba(0, 0, 0, .3);
            flex: 1;
            opacity: 1;
            margin: 10px 0px 10px 10px;
        }

        .Splash-content {
            opacity: 1;
            width: 100%;
            max-width: 800px;

            >div {
                >div {
                    padding: 0px;
                }
            }
        }

        @media screen and (max-width: $medium) {
            .Splash-main {
                opacity: 0;
                margin: 0px;
            }

            .Splash-content {
                max-width: 100%;
                margin: 0px 4px;
            }
        }
    }
}