.main {
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    user-select: none;
    transition: width 0.3s ease-in-out;

    >div {
        background-color: var(--WindowComponent-content-background);
        border: 2px solid var(--WindowComponent-content-border-color);
        border-radius: 10px;
        margin-bottom: 10px;
        padding: 5px 0px;

        &:last-child {
            margin-bottom: 0px;
        }

        .space-logo {
            height: 50px;
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            color: var(--text-primary);
            line-height: normal;
            opacity: 0.9;
            overflow: hidden;

            &,
            & * {
                transition: all 0.2s;
            }

            &> {
                svg {
                    width: 40px;
                    min-width: 40px;
                    height: 40px;
                    min-height: 40px;
                    color: var(--MenubarComponent-icon-color);
                }

                span {
                    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
                    font-size: 25px;
                    margin-left: 10px;
                    padding-top: 5px;
                    opacity: 1;
                }
            }
        }

        .space-profile {
            margin-top: 15px;
            position: relative;
            display: flex;
            flex-direction: column;

            &> {
                .profile-info {
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    cursor: pointer;
                    padding-bottom: 4px;
                    align-items: center;

                    &:hover {
                        opacity: 0.8;
                    }

                    &>* {
                        &:first-child {
                            padding: 0px 14px 0px 6px;

                            * {
                                margin: 0px !important;
                            }
                        }

                        &:last-child {
                            flex: 1;
                            margin: 0px;
                            overflow: hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            font-family: Arial, Helvetica, sans-serif;
                            color: var(--text-primary);
                            font-size: 15px;
                        }
                    }

                    .profile-account {
                        display: flex;
                        flex-direction: column;
                        gap: 6px;
                        width: auto;
                    }

                    .profile-account .profile-type {
                        font-size: 11px;
                        // color: var(--text-secondary);
                        color: rgb(249, 249, 249);
                        font-weight: 400;
                        border: 2px solid var(--WindowComponent-content-border-color);
                        background-color: var(--MenubarComponent-MenuItem-background);
                        border-radius: 10px;
                        margin: 0px 2px;
                        padding: 2px 4px;
                        width: 35%;
                        text-align: center;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }

                .profile-wallet {
                    display: flex;
                    flex-direction: row;
                    padding: 5px 5px;
                    margin-top: 5px;
                    line-height: normal;
                    color: var(--text-primary);
                    opacity: 1;
                    transition: all 0.2s;

                    &> {
                        div {
                            position: relative;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            border: 2px solid var(--WindowComponent-content-border-color);
                            background-color: rgba(0, 0, 0, 0.1);
                            border-radius: 10px;
                            margin: 0px 2px;
                            padding: 5px 8px;
                            flex: 1;
                            flex-wrap: nowrap;
                            width: 100%;

                            &> {
                                .profile-wallet-prise-icon {
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    justify-content: center;
                                    width: 25px;
                                    height: 25px;
                                    color: #ff6d00;
                                }

                                .profile-wallet-prise-icon>svg {
                                    width: 80%;
                                    height: 80%;
                                }

                                .profile-wallet-prise-value {
                                    font-size: 10px;
                                    flex: 1;
                                    font-weight: bold;
                                    text-align: center;
                                    white-space: nowrap;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    padding: 0px 4px;
                                }

                                .profile-wallet-prise-percent {
                                    font-size: 10px;
                                    text-align: right;
                                    color: var(--text-primary);

                                    &.red {
                                        color: var(--error-main);
                                    }

                                    &.green {
                                        color: var(--success-main);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.space-menu {
            flex: 1;
            display: flex;
            flex-direction: column;
            height: 0px;
            padding-top: 0px;

            &>.list {
                flex: 1;
                padding: 5px;
                height: 100%;
                overflow-x: hidden;
                overflow-y: auto;
                border-bottom: 2px solid var(--WindowComponent-content-border-color);

                &::-webkit-scrollbar {
                    width: 10px;
                    height: 10px;
                    display: none;
                }

                .MenuItem-buttom {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    cursor: pointer;
                    border-radius: 10px;
                    color: var(--text-primary);
                    padding: 5px;
                    margin: 4px 0px;
                    padding: 10px;
                    border: 2px solid transparent;

                    &:hover {
                        background-color: var(--WindowComponent-content-border-color);
                    }

                    &.disabled {
                        cursor: auto;

                        >* {
                            opacity: 0.5;
                        }

                        &:hover {
                            background-color: transparent;
                        }
                    }

                    &>.icon {
                        width: 24px;
                        height: 24px;
                        min-width: 24px;
                        min-height: 24px;
                        max-width: 24px;
                        max-height: 24px;
                        overflow: hidden;

                        svg {
                            width: 24px;
                            height: 24px;
                        }
                    }

                    &>.label {
                        flex: 1;
                        font-size: 14px;
                        padding-left: 15px;
                        overflow: hidden;
                        align-items: center;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        transition: opacity 0.3s ease-in-out;
                    }

                    &.active {
                        background-color: var(--MenubarComponent-MenuItem-background);
                        border-color: var(--WindowComponent-content-border-color);
                        cursor: auto;
                        color: var(--MenubarComponent-MenuItem-active-color);
                        font-weight: bold;

                        &>.icon svg {
                            color: currentColor;
                        }
                    }
                }

                .MenuSubmenu-submenu {
                    &>.content {
                        display: none;
                        display: flex;
                        color: var(--MenubarComponent-MenuSubmenu-color);
                        padding: 5px;
                        cursor: pointer;
                        background-color: var(--WindowComponent-content-border-color);
                        border-radius: 10px;

                        &:hover>* {
                            opacity: 0.6;
                        }

                        &>svg {
                            width: 24px;
                            height: 24px;
                            fill: currentColor;
                            transform: rotate(0deg);
                            transform-origin: center;
                        }

                        &>.label {
                            flex: 1;
                            font-size: 14px;
                            padding: 5px;
                            white-space: nowrap;
                            overflow: hidden;
                            height: 25px;
                            display: flex;
                            align-items: center;
                        }

                        &>.icon {
                            width: 24px;
                            height: 24px;
                            min-width: 24px;
                            min-height: 24px;
                            max-width: 24px;
                            max-height: 24px;
                            overflow: hidden;

                            svg {
                                width: 24px;
                                height: 24px;
                            }
                        }
                    }

                    &>.itens {
                        max-height: 0px;
                        overflow: hidden;
                        transform-origin: top;
                        transition: max-height 0.2s ease-in;
                        margin-bottom: 5px;
                        border: solid 2px transparent;
                        border-radius: 0px 0px 10px 10px;

                        &>* {
                            opacity: 0;
                            transition: opacity 0.2s ease-in;
                            border-radius: 0px;
                            margin: 0px;

                            &:last-child {
                                margin-bottom: 0px;
                                border-radius: 0px 0px 10px 10px;
                            }
                        }
                    }

                    &.active {
                        &>.content {
                            border-radius: 10px 10px 0px 0px;

                            &>svg {
                                transform: rotate(180deg);
                            }
                        }

                        &>.itens {
                            border-color: var(--WindowComponent-content-border-color);

                            &>* {
                                border-width: 0px;
                                opacity: 1;

                                &.MenuItem-buttom {
                                    &.MenuItem-active {
                                        position: relative;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &>.space-action {
                padding-top: 10px;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-evenly;

                &>div.btn {
                    background: transparent;
                    border-radius: 50%;
                    color: var(--text-primary);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 28px;
                    width: 28px;
                    cursor: pointer;
                    border: 2px solid transparent;

                    &:hover>* {
                        opacity: 0.6;
                    }

                    &>svg {
                        font-size: 20px;
                    }

                    &.action {
                        background-color: var(--MenubarComponent-MenuItem-background);
                        color: var(--MenubarComponent-MenuItem-active-color);
                        border-color: rgba(0, 0, 0, 0.2);

                        &>svg {
                            opacity: 0.9;
                        }
                    }
                }
            }
        }
    }

    &:not(.expanded)>div {
        .space-logo {
            justify-content: start;
            padding-left: 6px;
            height: 30px;

            &> {
                svg {
                    height: 25px;
                    min-height: 25px;
                }

                span {
                    opacity: 0;
                }
            }
        }

        .space-profile> {
            .profile-wallet {
                opacity: 0;
                height: 0px;
                padding: 0px;
                margin-top: 0px;
                overflow: hidden;
            }
        }

        &.space-menu {
            &>.list {
                .MenuSubmenu-submenu {
                    &>.content {
                        display: none;
                    }

                    &>.itens {
                        max-height: inherit !important;
                        border-color: transparent;
                        border-width: 0px;

                        >* {
                            opacity: 1;
                            border-width: 2px;
                            border-radius: 10px;
                            margin: 4px 0px;
                        }
                    }
                }
            }

            &>.space-action {
                flex-direction: column;

                >* {
                    margin-bottom: 10px;

                    &:last-child {
                        margin-bottom: 0px;
                    }
                }
            }
        }
    }
}

.main>.space-bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.main>.space-bottom>.toggle {
    width: 55px;
    height: 95%;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    color: var(--MenubarComponent-color);
}

.main>.space-bottom>.toggle:hover {
    opacity: 0.7;
}

.main>.space-bottom>.toggle>svg {
    width: auto;
    height: 100%;
    fill: currentColor;
    transform: rotate(180deg);
    transition: transform 0.3s ease-in-out;
}

.main.expanded>.space-bottom>.toggle>svg {
    transform: rotate(0deg);
}