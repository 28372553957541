.select-currency {
    display: flex;
    flex-direction: row;
    background: var(--action-hover);
    border: 2px solid var(--action-hover);
    border-radius: 10px;
    flex: 1;
    width: 100%;
    user-select: none;
    align-items: center;
    overflow: hidden;

    .select-label {
        padding: 10px 20px;
        border-right: 2px solid var(--action-hover);
        margin-right: 2px;
    }

    .select-item {
        display: flex;
        flex-direction: row;
        cursor: pointer;
        align-items: center;
        overflow: hidden;
        padding: 10px 20px;
        flex: 1;

        &.active {
            border-color: var(--primary-main);
        }

        &:hover {
            background: var(--action-focus);
        }

        >div:first-child {
            background-color: var(--action-hover);
            background-size: 50% !important;
            border-radius: 50%;
            min-width: 40px !important;
            min-height: 40px !important;
        }

        >div:last-child {
            position: relative;

            >h6 {
                margin: 0px;
                font-size: 18px;
                line-height: 20px;
            }

            >div {
                margin: 0px;
                font-size: 10px;
                line-height: 12px;
                opacity: .6;
                white-space: nowrap;
                max-width: 100%;
            }
        }
    }
}