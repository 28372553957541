.main {
    height: auto;
    min-height: 37px;
    user-select: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    background: transparent;

    & > div{
        border-bottom-right-radius: 15px;
    }

    .rank {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 5px 15px;

        &::after{
            content: "";
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            height: auto;
            width: 1px;
            background-color: var(--text-primary);
            opacity: .3;
        }

        & > .icon {
            height: 20px;
            width: 20px;
            min-height: 20px;
            min-width: 20px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: cover;
        }

        & > .content {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            padding-left: 10px;
            color: var(--text-primary);

            & > .price {
                font-size: 12px;
                font-weight: bold;
                min-width: 100px;
            }

            & > .name {
                font-size: 8px;
                letter-spacing: 3px;
            }
        }

        & > .percent {
            font-size: 10px;
            font-weight: bold;
            color: var(--success-main);

            &.fall {
                color: var(--error-main);
            }
        }

        & > .indicator {
            display: flex;
            flex-direction: row-reverse;
            height: 25px;
            width: auto;
            align-items: center;
            justify-content: center;
            margin-left: 5px;
            color: var(--success-main);
            font-size: 12px;
            font-weight: bold;

            & > svg{
                transform: scaleY(1);
            }

            &.fall {
                color: var(--error-main);

                & > svg{
                    transform: scaleY(-1);
                }
            }

            & > span{
                margin-right: 5px;
            }
        }
    }
}
