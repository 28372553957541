.form {
  margin: 4;
  
  .section {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 4px;
    margin-bottom: 4px;
    
    input {
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 2px;
      margin-bottom: 2px;
    }
    
    .unit {
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 4px;
      margin-bottom: 4px;
      
      input {
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 2px;
        margin-bottom: 2px;
      }
      
      .level {
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 4px;
        margin-bottom: 4px;
        
        .quiz {
          margin-bottom: 2px;
          
          input {
            border: 1px solid #ccc;
            border-radius: 4px;
            padding: 2px;
            margin-bottom: 2px;
          }
        }
        
        button {
          background-color: #007bff;
          color: #fff;
          border-radius: 4px;
          padding: 4px;
          margin-top: 4px;
        }
      }
      
      button {
        background-color: #007bff;
        color: #fff;
        border-radius: 4px;
        padding: 4px;
        margin-top: 4px;
      }
    }
    
    button {
      background-color: #007bff;
      color: #fff;
      border-radius: 4px;
      padding: 4px;
      margin-top: 4px;
    }
  }
  
  .add-section-button {
    background-color: #007bff;
    color: #fff;
    border-radius: 4px;
    padding: 4px;
    margin-top: 4px;
  }
  
  .submit-button {
    background-color: #28a745;
    color: #fff;
    border-radius: 4px;
    padding: 4px;
    margin-top: 4px;
  }
}
.form {
  margin: 4;
  
  .section {
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 4px;
    margin-bottom: 4px;
    
    input {
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 2px;
      margin-bottom: 2px;
    }
    
    .unit {
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 4px;
      margin-bottom: 4px;
      
      input {
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 2px;
        margin-bottom: 2px;
      }
      
      .level {
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 4px;
        margin-bottom: 4px;
        
        .quiz {
          margin-bottom: 2px;
          
          input {
            border: 1px solid #ccc;
            border-radius: 4px;
            padding: 2px;
            margin-bottom: 2px;
          }
        }
        
        button {
          background-color: #007bff;
          color: #fff;
          border-radius: 4px;
          padding: 4px;
          margin-top: 4px;
        }
      }
      
      button {
        background-color: #007bff;
        color: #fff;
        border-radius: 4px;
        padding: 4px;
        margin-top: 4px;
      }
    }
    
    button {
      background-color: #007bff;
      color: #fff;
      border-radius: 4px;
      padding: 4px;
      margin-top: 4px;
    }
  }
  
  .add-section-button {
    background-color: #007bff;
    color: #fff;
    border-radius: 4px;
    padding: 4px;
    margin-top: 4px;
  }
  
  .submit-button {
    background-color: #28a745;
    color: #fff;
    border-radius: 4px;
    padding: 4px;
    margin-top: 4px;
  }
}
