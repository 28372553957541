body {
    overflow: hidden;

    :global(.os-scrollbar) {
        --os-size: 10px;
        --os-padding-axis: 10px;
    }

    .main {
        flex: 1;
        height: 100%;
        overflow: hidden;
        background-color: var(--WindowComponent-background);
        margin: 0px;
        z-index: 0;
        display: flex;
        flex-direction: column;

        >* {
            z-index: 0;
        }

        .split-menu {
            width: 60px;
            transition: width .3s ease-in-out;
        }

        .split-bar {
            width: 6px;
            cursor: col-resize;
            margin: 2px;
            border-radius: 3px;
            background-color: transparent;
            transition: background-color .3s ease-in-out;
            -ms-touch-action: none;
            touch-action: none;
            z-index: 2;

            >svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 30px;
                height: 30px;
                color: var(--text-primary);
                opacity: 0;
                transition: opacity .3s ease-in-out;
            }

            &:hover,
            &:focus,
            &:active {
                background-color: rgba(0, 0, 0, .2);

                >svg {
                    opacity: .8;
                }
            }
        }

        .mobile-menu {
            position: absolute;
            top: 0px;
            left: 0px;
            bottom: 0px;
            z-index: 4;
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            width: 80vw;
            max-width: 500px;
            transform: translateX(-100%);
            transition: transform .3s ease-in-out;

            .mobile-menu-dots {
                position: absolute;
                bottom: 0px;
                right: -50px;
                width: 50px;
                height: 47px;
                background-color: var(--WindowComponent-content-background);
                z-index: 3;
                display: flex;
                align-items: center;
                justify-content: center;
                color: var(--text-primary);
                border: 0px solid rgba(0, 0, 0, .2);
                border-top-width: 2px;
                border-right-width: 2px;
                border-bottom-width: 2px;
                border-radius: 0px 20px 20px 0px;
                cursor: pointer;
                transition: background-color .3s ease-in-out;

                &:hover {
                    background-color: var(--WindowComponent-background);
                }

                >svg {
                    width: 35px;
                    height: 35px;
                    transition: transform .3s ease-in-out;
                    transform-origin: center;
                }
            }

            .split-menu {
                flex: 1;
                height: 100%;
                background-color: var(--MenubarComponent-mobile-background);
                padding: 10px;
                border-right: 2px solid rgba(0, 0, 0, .2);
                border-radius: 0px 10px 0px 0px;
            }

            .split-bar {
                position: absolute;
                top: 0px;
                right: -10px;
                bottom: 0px;
                width: 10px;
                margin: 0px;
                border-radius: 0px;

                >svg {
                    width: 40px;
                    height: 40px;
                }
            }
        }

        .mobile-menu-backdrop {
            position: absolute;
            top: 0px;
            left: 0px;
            right: 0px;
            bottom: 0px;
            background-color: rgba(0, 0, 0, .4);
            // backdrop-filter: blur(5px);
            opacity: 0;
            z-index: -1;
        }

        .menu-summary {
            $width: 60px;
            $padding: 4px;
            $item-width: calc($width - $padding * 2);

            position: fixed;
            height: calc($width + $padding);
            padding: $padding;
            min-width: 47px;
            border-radius: calc($width / 2);
            background-color: var(--WindowComponent-content-background);
            z-index: 2;
            left: 50%;
            bottom: 20px;
            transform: translateX(-50%);
            border: solid 2px var(--WindowComponent-content-border-color);
            box-shadow: 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12), 0 2px 4px -1px rgba(0, 0, 0, .2);
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: center;
            transition: bottom .4s ease-in-out;

            &.hidden,
            &.forceHidden {
                bottom: - calc($width + 20px);
            }

            >hr {
                border-color: rgba(0, 0, 0, .2);
                border-right-width: 2px;
                margin: 4px 5px 4px 0px !important;
            }

            >div {
                width: $item-width;
                height: $item-width;
                background-color: transparent;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-right: 5px;
                color: var(--text-primary);
                cursor: pointer;
                transform: scale(.9);
                transition: all .2s ease-in-out;

                &:last-child {
                    margin-right: 0px;
                }

                >svg {
                    opacity: .6;
                    width: 30px;
                    height: 30px;
                }

                &:hover {
                    background-color: rgba(0, 0, 0, .2);

                    >svg {
                        opacity: .8;
                    }
                }

                &.active {
                    background-color: var(--MenubarComponent-MenuItem-background);
                    border: solid 2px rgba(0, 0, 0, 0.2);
                    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12), 0 2px 4px -1px rgba(0, 0, 0, .2);
                    transform: scale(1);
                    color: #ffffff;

                    >svg {
                        opacity: 1;
                    }
                }
            }
        }

        .view {
            flex: 1;
            display: flex;
            flex-direction: row;
            overflow: hidden;
            padding: 0px;

            .container {
                flex: 1;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                background-color: transparent;
                border: 0px solid transparent;

                >.background-gradient {
                    background-color: #2196F3;
                    background-image: linear-gradient(var(--WindowComponent-content-gradient-base) 0, var(--WindowComponent-content-background) 100%), var(--background-noise);
                    height: 500px;
                    position: absolute;
                    width: 100%;
                    z-index: 0;
                }

                >.content {
                    flex: 1;
                    overflow: hidden;
                    display: flex;
                    flex-direction: column;
                    overflow: hidden;
                    overflow-y: auto;
                    z-index: 2;
                }
            }

            &:not(.full-view) {
                padding: 5px;

                .container {
                    background-color: var(--WindowComponent-content-background);
                    border: 2px solid var(--WindowComponent-content-border-color);
                    border-radius: 10px;
                }
            }
        }

        .notification-button {
            position: fixed;
            right: 0px;
            bottom: 0px;
            width: 50px;
            height: 40px;
            background-color: var(--WindowComponent-background);
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--text-primary);

            &::before {
                content: "";
                position: absolute;
                top: 0px;
                bottom: 0px;
                left: -20px;
                width: 20px;
                height: auto;
                background-image: linear-gradient(to right, rgba(0, 0, 0, 0), var(--WindowComponent-background));
            }

            >div {
                cursor: pointer;
                opacity: .5;
                transition: opacity .3s ease-in-out;

                &:hover {
                    opacity: 1;
                }
            }
        }

        &.small-size {
            flex-direction: column-reverse;

            .view:not(.full-view) {
                padding: 0px;

                .container {
                    border-radius: 10px 10px 0px 0px;
                    border-bottom-width: 0px;
                    border-left-width: 0px;
                    border-right-width: 0px;
                }
            }

            .notification-button {
                display: none;
            }
        }
    }
}