$cell-size: 100px;
$gap-size: 1px;

.calendar-container {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-auto-rows: $cell-size;
  gap: $gap-size;
  height: 100%;
  color: rgb(170, 170, 170);

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

ul {
  list-style: none;
}

.day-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-weight: bold;
  border: 1px solid rgba(147, 147, 147, 0.8);
  background: rgb(116, 116, 116);
  background: linear-gradient(90deg, rgba(116, 116, 116, 0.1) 0%, rgba(0, 0, 0, 0.1) 52%, rgba(0, 0, 0, 0.1) 100%);
  gap: 4px;
  border-radius: 8px;
}

.dayOfTheWeek {
  text-align: center;
}

.ball {
  width: 25px;
  height: 25px;
  border-radius: 8px;
  background-color: rgb(244, 181, 104);
  text-align: center;
  color: black;
}

.ball-list {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.dayOfTheWeekNumber {
  color: rgb(205, 91, 91);
}

.highlighted {
  background-color: rgb(205, 91, 91);
  cursor: pointer;
  transition: 1.5s;
}

.date-calendar {
  width: 300px;
  height: 300px;
}

.within-schedule {
  width: 100%;
  background-color: #ffe0b2; /* Cor de fundo quando está dentro do período do calendário (schedule) */
}