.root {
  font-size: 14px;
  font-weight: 400;
  button {
    padding: 8px;
    // border: none;
    border-radius: 2px;
  }
  .tradingview-main-content {
    display: flex;
    font-weight: 600;
    height: 100%;
  }

  .attach-money,
  .percent-icon {
    background-color: rgba(0, 0, 0, 0.6);
    border-radius: 8px;
    cursor: pointer;
  }

  .trading-view-left {
    width: 85%;
    height: 100%;
  }

  .trading-view-right {
    width: 15%;
    height: 100%;
    padding: 12px;
  }

  .trading-chart-options {
    display: flex;
    flex-direction: column;
  }

  .control-icons {
    display: flex;
    cursor: pointer;
    justify-content: center;
  }

  .trading-chart-total-buttons {
    display: flex;
    gap: 4px;
  }

  .trading-chart-total-buttons-item {
    flex-grow: 1;
    padding: 8px;
    background-color: rgba(0, 0, 0, 0.7);
    color: #ffffff;
    cursor: pointer;
  }

  .trading-chart-total-result {
    display: flex;
    color: #ffffff8f;
    padding-block: 10px;
  }

  .trading-chart-strategy,
  .trading-chart-price,
  .trading-chart-stop,
  .trading-chart-quantity {
    width: 100%;
    height: 40px;
    flex-grow: 1;
    display: flex;
    color: #ffffff8f;
    justify-content: space-between;
    align-items: center;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .trading-chart-strategy p,
  .trading-chart-price p,
  .trading-chart-stop p,
  .trading-chart-quantity p {
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .trading-chart-price {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .trading-chart-price p {
    width: 33%;
    height: 25px;
  }

  .select_menu {
    width: 33%;
    height: 25px;
  }

  .add_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 33%;
    height: 25px;
  }

  .add_icon_image {
    width: 20px;
    margin: 0 auto;
  }

  .trading-chart-price input {
    width: 33%;
    height: 25px;
  }

  .trading-chart-price .control-icons {
    align-items: center;
    width: 33%;
    height: 25px;
  }

  .trading-chart-stop p {
    width: 33%;
    height: 25px;
  }

  .trading-chart-stop input {
    width: 33%;
    height: 25px;
  }

  .trading-chart-stop .control-icons {
    width: 33%;
    height: 25px;
  }

  .trading-chart-quantity p {
    width: 33%;
    height: 25px;
  }

  .trading-chart-quantity input {
    width: 33%;
    height: 25px;
  }

  .trading-chart-quantity .control-icons {
    width: 33%;
    height: 25px;
  }

  #total-id {
    width: 33%;
    height: 25px;
  }

  .trading-chart-total-result input {
    width: 33%;
    height: 25px;
  }

  .trading-chart-total-result .control-icons {
    width: 33%;
    height: 25px;
  }

  .trading-chart-strategy {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .trading-chart-strategy p {
    width: 33%;
    height: 25px;
  }

  .trading-chart-item {
    min-width: 33.3%;
    text-align: right;
    padding-right: 4px;
  }

  .input {
    background-color: transparent;
    border: 1px solid var(--Widget-border);
    border-radius: 4px;
    color: #ffffff;
  }

  .button-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    border-radius: 8px;
    gap: 4px;
  }

  .button {
    width: 100px;
    font-weight: 600;
    // border: none;
    cursor: pointer;
  }

  .button p {
    font-size: 12px;
  }

  .green {
    background-color: rgb(246, 70, 93);
    color: #ffffff;
    border-radius: 6px;
  }

  .grey {
    background-color: rgb(71, 77, 87);
    color: #ffffff;
    border-radius: 6px;
  }

  .grey:hover {
    transition: 0.5s;
    background-color: rgb(130, 135, 142);
  }

  .orange {
    background-color: rgb(14, 203, 129);
    color: white;
    border-radius: 6px;
  }

  .orange:hover {
    transition: 0.5s;
    background-color: rgb(50, 217, 147);
  }

  .button-reset,
  .button-cancel {
    border: none;
    width: 100%;
    font-weight: 600;
  }

  .button-reset {
    background-color: red;
    border-radius: 6px;
    color: #ffffff;
  }

  .button-reset:hover,
  .button-cancel:hover {
    transition: 0.5s;
    background-color: rgb(255, 112, 126);
  }

  .green:hover {
    transition: 0.5s;
    background-color: rgb(255, 112, 126);
  }

  .button-cancel {
    background-color: red;
    border-radius: 6px;
    color: #ffffff;
  }

  .result {
    display: flex;
    align-items: center;
    color: #ffffff8f;
    justify-content: space-between;
    width: 100%;
  }

  .result p {
    padding: 4px;
    margin-block: 8px;
  }

  .result_opened,
  .result-medium {
    margin-bottom: 4px;
  }

  .result_opened,
  .result_daily,
  .result-medium,
  .result-medium-total {
    padding: 8px;
    height: 40px;
    border-radius: 8px;
  }

  .result-container,
  .result-medium-container {
    width: 100%;
  }

  .result_day {
    color: rgb(218, 54, 54);
  }

  .result_resume {
    color: #ffffff8f;
  }

  .result_opened,
  .result_daily,
  .result-medium,
  .result-medium-total {
    display: flex;
    justify-content: space-between;
    background-color: #0f0f0fb2;
  }

  .result-medium,
  .result-medium-total {
    color: #ffffff8f;
  }

  @media (max-width: 1400px) {
    .trading-view-left {
      width: 75%;
      height: 100%;
    }

    .trading-view-right {
      width: 25%;
      height: 100%;
      padding: 12px;
    }

    .button {
      width: 80px;
    }
  }

  @media (max-width: 1000px) {
    .trading-view-left {
      width: 65%;
      height: 100%;
    }

    .trading-view-right {
      width: 35%;
      height: 100%;
      padding: 12px;
    }

    .button {
      width: 55px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
