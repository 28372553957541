.main{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    & > div{
        width: 80%;
        max-width: 800px;
    }
}