.mover-griD-from-scren-four {
    margin-top: 40px;
    margin-left: 7px;
    margin-right: 7px;
}
 .mY-cex-todos-dex{
    display: flex;
    justify-content: space-between;
    margin-top: -36px;
    background-color: var(--Tela4-background);
    border-radius:5px 5px 0px 0px; 
}
.cEX{
    border-radius:1px;
    margin-top:-5px; 
}
.cEX:hover{
    border-radius:5px 0px 0px 0px; 
    background-color:rgba(255, 255, 255, 0.2)!important;
}
.cEX button{
    background-color:transparent !important;
}
.tOdOS{
    margin-top:-5px;  
    border-radius:1px; 
}
.tOdOS:hover{
    background-color:rgba(255, 255, 255, 0.2)!important;
}
.tOdOS button{
    background-color:transparent !important;
}
.DeX{
    margin-top:-5px; 
    border-radius:1px; 
}
.DeX:hover{
    border-radius:0px 5px 0px 0px; 
    background-color:rgba(255, 255, 255, 0.2)!important;
}
.DeX button{
    background-color:transparent !important;
}
.listias-de-ativos {
    margin-top: 16px;
    margin-bottom: 12px;
    font-size: 12px;
    color: rgb(0, 0, 0);
    text-align: center;
}
.ajustEi-todo {
    background-color: var(--Tela4-background);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 40px;
    padding: 10px;
}
.ajustEi-tOdo{
    background-color: var(--Tela4-background);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 1px;
    padding: 10px;
}
.ajustEI-todo{
    background-color: var(--Tela4-background);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 1px;
    padding: 10px;
}
.ajUstEi-todo{
    background-color: var(--Tela4-background);
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 1px;
    padding: 10px;
    
}
.AvatetionsS{
    margin-left: 7px;
}
.icone-from-imagem{
    margin-top:3px;
    margin-left: 0px;
}
.avatetions{
    margin-right:3px;
}
.aVatar-junio{
 margin-top:6px;
 margin-left: 1px;
}
.Primiero-MeTaMasK{
    margin-left:0px;
}
.Primiero-MeTaMasK:hover{
    background-color:rgba(255, 255, 255, 0.2)!important;
}
.Primiero-MeTaMasK button{
background-color:transparent !important;
}
.casinhA-los-numeros{
    display: flex;
    justify-content:center;
    margin-right:30px;
    font-size: 10px;
    color:var(--text-primary);
}
.casinha-dos-numeros{
    font-size: 10px;
    color:var(--text-primary);
    margin-right:20px;
}
.froM-baby-doge{
   color:var(--text-primary);
   font-size:10px;
}
.MeTaMasK:hover{
    background-color:rgba(255, 255, 255, 0.2)!important;
}
.MeTaMasK button{
    background-color:transparent !important;
}
.MeTa-Da-MasK:hover{
    background-color:rgba(255, 255, 255, 0.2)!important;
}
.MeTa-Da-MasK button{
    background-color:transparent !important;
}
.my-numEros{
    display: flex;
    justify-content:center;
    margin-right:45px;
    font-size: 10px;
    color:var(--text-primary);
}
.fRom-bAby-doge{
    color:var(--text-primary);
    font-size:10px;
}
.metA-mAks-penultimo{
    margin-right:10px;
}
.metA-mAks-penultimo:hover{
    background-color:rgba(255, 255, 255, 0.2)!important;
}
.metA-mAks-penultimo button{
    background-color:transparent !important;
}
.casInha-dos-numeros-ultimo{
    display: flex;
    justify-content:center;
    margin-right:30px;
    font-size: 10px;
    color:var(--text-primary);
}
.MuiTypography-root{
    color: var(--text-primary);
}


