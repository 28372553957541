.main {
    --color-primary: 97, 97, 97;
    --text-color: 255, 255, 255;

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 800px;
    margin-bottom: 180px;

    * {
        font-family: "din-round", sans-serif !important;
        z-index: 1;
    }

    .noPage {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        color: var(--text-primary);
        width: 100%;
        min-height: 200px;
        padding: 30px 15px;
        opacity: .5;

        svg {
            width: 40px;
            height: 40px;
            margin-right: 10px;
        }
    }

    .unit_summary {
        --text-primary: rgb(var(--text-color));
        background-color: rgb(var(--color-primary));
        border: 2px solid rgba(0, 0, 0, .2);
        padding: 10px 25px;
        border-radius: 10px;
        color: rgb(var(--text-color));
        margin: 25px auto;
        box-shadow: 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12), 0 5px 5px -3px rgba(0, 0, 0, .2);
        width: 100%;

        >* {
            margin: 5px 0px;
        }

        >p {
            opacity: .8;
        }

        &.locked {
            >* {
                opacity: .5;
            }
        }
    }

    .levelButton {
        position: relative;
    }

    &::before {
        content: "";
        position: absolute;
        top: 30px;
        bottom: -210px;
        left: 50%;
        transform: translateX(-50%);
        width: 80%;
        height: auto;
        min-height: 100vh;
        background-image: linear-gradient(to right, transparent, var(--text-primary), transparent);
        opacity: .1;
    }

    .levelInfoPopUp {
        --position-left: 0px;
        --opacity: 0.5;

        position: absolute;
        top: 0px;
        left: 50%;
        transform: translateX(-50%);
        width: 95%;
        max-width: 400px;
        background-color: rgb(var(--color-primary));
        border: 2px solid rgba(255, 255, 255, 0.1);
        border-radius: 15px;
        box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
        padding: 5px 15px 10px;
        z-index: 999999;

        >* {
            color: rgb(var(--text-color));
            opacity: var(--opacity);
            margin: 10px 0px;
            line-height: 1.2;

            &:last-child {
                margin-bottom: 0px;
            }
        }

        .header {
            display: flex;
            flex-direction: row;
            align-items: baseline;

            >* {
                &:first-child {
                    flex: 1;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                &:last-child {
                    background-color: rgba(0, 0, 0, .2);
                    padding: 5px 15px;
                    border-radius: 10px;
                }
            }
        }

        &::before {
            content: '';
            position: absolute;
            left: 50%;
            top: -9px;
            height: 15px;
            width: 15px;
            background-color: rgb(var(--color-primary));
            border: 2px solid rgba(255, 255, 255, 0.1);
            border-right-width: 0px;
            border-bottom-width: 0px;
            border-top-left-radius: 5px;
            transform: translateX(-50%) rotate(45deg);
            margin-left: var(--position-left);
            z-index: 0;
        }
    }

    @media screen and (max-width: 600px) {
        margin-bottom: 250px;

        &::before {
            bottom: -280px;
        }
    }
}