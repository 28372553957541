.main {
    width: 300px;
    height: 300px;
    overflow: hidden;

    >* {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transform-origin: 50% 50%;
        transform: translate(0, 0);

        &:first-child {
            circle {
                fill: none;

                &:first-child {
                    stroke: rgba(0, 0, 0, .2);
                    stroke-miterlimit: 10;
                    stroke-width: 9px;
                }

                &:last-child {
                    stroke: rgba(var(--color), 1);
                    stroke-miterlimit: 10;
                    stroke-width: 4px;
                    stroke-dasharray: var(--stroke-length) var(--stroke-length);
                    stroke-dashoffset: var(--stroke-offset);
                    transition: 0.35s stroke-dashoffset;
                    transform: rotate(-90deg);
                    transform-origin: 50% 50%;
                }
            }
        }
    }
}